import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import JSXBoard from "../../../JSXBoard";
import { log, jsClasses, getBoudingBoxArray } from "../../../utils/Utils";
import { useGfxContext } from "../../../context/GfxContext";
import { Table } from "react-bootstrap";

const StressInTendorsDraw = () => {
  /** Show log in console if true */
  const showLogs = false;
  /** Class name for log */
  const cls = jsClasses.StressInTendorsDraw;

  const boardRef = useRef();
  const stress_in_tendons = useRef([]);
  const draw_tendons_stress_lower_limit = useRef();
  const draw_tendons_stress_upper_limit = useRef();
  const tendons_stress_lower_limit = useRef();
  const tendons_stress_upper_limit = useRef();
  const chart = useRef();
  const polygon = useRef();

  const { complexResultDrawData } = useGfxContext();

  useEffect(() => {
    log(cls, "Otrzymano dane od API", complexResultDrawData, showLogs);

    if (complexResultDrawData.stress_in_tendons !== undefined) {
      boardRef.current.suspendUpdate();
      // eslint-disable-next-line array-callback-return
      stress_in_tendons.current = complexResultDrawData.stress_in_tendons;
      draw_tendons_stress_lower_limit.current =
        complexResultDrawData.draw_tendons_stress_lower_limit;
      draw_tendons_stress_upper_limit.current =
        complexResultDrawData.draw_tendons_stress_lower_limit;
      tendons_stress_lower_limit.current =
        complexResultDrawData.tendons_stress_lower_limit;
      tendons_stress_upper_limit.current =
        complexResultDrawData.tendons_stress_upper_limit;
      boardRef.current.removeObject(chart.current, false);
      boardRef.current.removeObject(polygon.current, false);
      createDraw();
      boardRef.current.unsuspendUpdate();
    }

    if (complexResultDrawData.stress_in_tendons !== undefined) {
      updateBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complexResultDrawData]);

  const gfx = (board) => {
    boardRef.current = board;
  };

  const updateBoard = () => {
    if (boardRef.current !== undefined) {
      boardRef.current.fullUpdate();
    }
  };

  const createDraw = () => {
    boardRef.current.setBoundingBox(
      getBoudingBoxArray(stress_in_tendons.current),
      false,
      "reset"
    );
    chart.current = boardRef.current.create(
      "chart",
      stress_in_tendons.current,
      {
        chartStyle: "bar",
        colors: ["#1E90FF"],
        width: 0.1,
        fillOpacity: 0.5,
        dir: "horizontal",
      }
    );
    if (
      Boolean(draw_tendons_stress_lower_limit.current) !== false &&
      typeof draw_tendons_stress_lower_limit.current === "number"
    ) {
      polygon.current = boardRef.current.create(
        "polygon",
        [
          [draw_tendons_stress_lower_limit.current, 20],
          [draw_tendons_stress_lower_limit.current, -20],
        ],
        {
          borders: { strokeWidth: 0.7, strokeColor: "red" },
          fillOpacity: 0.5,
          fillColor: "#cdfee0",
        }
      );
    }
    if (
      Boolean(draw_tendons_stress_upper_limit.current) !== false &&
      typeof draw_tendons_stress_upper_limit.current === "number"
    ) {
      polygon.current = boardRef.current.create(
        "polygon",
        [
          [draw_tendons_stress_upper_limit.current, 20],
          [draw_tendons_stress_upper_limit.current, -20],
        ],
        {
          borders: { strokeWidth: 0.7, strokeColor: "red" },
          fillOpacity: 0.5,
          fillColor: "#cdfee0",
        }
      );
    }
  };

  return (
    <Container>
      <JSXBoard
        logic={gfx}
        boardAttributes={{
          axis: true,
          boundingbox: [-0.1, 2, 2, -0.25],
          showCopyright: false,
        }}
        style={{
          border: "1px solid black",
        }}
      />
      <Table striped bordered hover>
        <tr>
          <td className="text-danger">
            {typeof draw_tendons_stress_lower_limit.current === "number" && (
              <h6>{tendons_stress_lower_limit.current}</h6>
            )}
          </td>
          <td className="text-danger">
            {typeof draw_tendons_stress_upper_limit.current === "number" && (
              <h6>{tendons_stress_upper_limit.current}</h6>
            )}
          </td>
        </tr>
      </Table>
    </Container>
  );
};

export default StressInTendorsDraw;
