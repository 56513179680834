import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import JSXBoard from "./Board";
import { log, jsClasses } from "../../../utils/Utils";
import { createSectionDoubleTeePoints } from "./DoubleTeePoints";
import { useGfxContext } from "../../../context/GfxContext";
import { drawDoubleTeeDimensionLines } from "./DoubleTeeDimensionLines";
import { drawValues } from "./DoubleTeeValues";
import { drawTendons } from "./DoubleTeeTendons";

const DoubleTeeMain = () => {
  /** Show log in console if true */
  const showLogs = true;
  /** Class name for log */
  const cls = jsClasses.ComplexDrawDoubleTeeMain;

  const { complexDrawData } = useGfxContext();

  let pointVisibile = true;
  let showLabel = false;

  const boardRef = useRef(); // Referencja do tablicy
  const xOffsetRef = useRef(0); // Punk początkowy do rysowania
  const points = useRef([]); // Tablica punktów rys. dynamicznego

  const b1 = useRef();
  const b2 = useRef();
  const b3 = useRef();
  const h = useRef(); // Suma wszystkich wysokości h
  const h1 = useRef();
  const h2 = useRef();
  const h3 = useRef();
  const tendon_info = useRef()

  useEffect(() => {
    initView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log(cls, "Data for draw", complexDrawData, showLogs);
    if (!complexDrawData.b.isNaN && !complexDrawData.h.isNaN) {
      b1.current = complexDrawData.b[0];
      b2.current = complexDrawData.b[1];
      b3.current = complexDrawData.b[2];
      h1.current = complexDrawData.h[0];
      h2.current = complexDrawData.h[1];
      h3.current = complexDrawData.h[2];
      h.current = h1.current + h2.current + h3.current;
      tendon_info.current = complexDrawData.tendon_info;
    }

    if (b3.current > 0.1) {
      updateBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complexDrawData]);

  const initView = () => {
    if (boardRef.current !== undefined) {
      createSectionDoubleTeePoints(
        boardRef.current,
        xOffsetRef,
        points,
        b1,
        b2,
        b3,
        h1,
        h2,
        h3,
        h,
        pointVisibile,
        showLabel
      );

      drawDoubleTeeDimensionLines(boardRef.current, points);
      createOutsideOutline();
      drawValues(boardRef.current, b1, b2, b3, h1, h2, h3, points);
      updateBoard();
    }
  };

  const gfx = (board) => {
    boardRef.current = board;
  };

  /**
   * Rysuje obrys zewnętrzny bryły.
   */
  const createOutsideOutline = () => {
    boardRef.current.create(
      "polygon",
      [
        ...points.current
      ],
      {
        borders: { strokeWidth: 5, strokeColor: "black" },
        fillOpacity: 1,
        fillColor: "#cdfee0",
      }
    );
  };

  /**
   * Uaktualnia zawartość tablicy
   */
  const updateBoard = () => {
    if (boardRef.current !== undefined) {
      boardRef.current.fullUpdate();
      boardRef.current.zoomElements([
        points.current[0],
        points.current[1],
        points.current[4],
        points.current[6],
      ]);

      if (b1.current !== undefined && h.current !== undefined) {
        const tendonsProps = {
          b: b1.current,
          h: h.current,
          h1: h1.current,
          h2: h2.current,
          h3: h3.current,
          tendon_info: tendon_info.current,
        };
        drawTendons(boardRef.current, tendonsProps, points.current);
      }
    }
  };

  return (
    <Container>
      <JSXBoard
        logic={gfx}
        boardAttributes={{
          axis: false,
          showCopyright: false,
          keepAspectRatio: true
        }}
        style={{
          border: "1px solid black",
        }}
      />
    </Container>
  );
};

export default DoubleTeeMain;
