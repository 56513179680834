/**
 * Component containing selection options (concrete classes) for 'Form.Select' component.
 */

import React from "react";

import { log, jsClasses } from "../../utils/Utils";

/** Show log in console if true */
const showLogs = false;
/** Class name for log */
const cls = jsClasses.FormConcreteSelectOption;

const FormConcreteSelectOption = ({ concrete }) => {
  if (concrete === undefined) {
    log(cls, "UNDEFINED", concrete, showLogs);
    return (
      <>
        <option value="1">C30_37</option>
      </>
    );
  } else {
    log(cls, "NOT UNDEFINED", concrete, showLogs);
    return (
      <>
        {concrete.map((cnClass) => {
          if (cnClass.cnClass === "C30/37") {
            return (
              <option key={cnClass.id} value={cnClass.cnClass} selected>
                {cnClass.cnClass}
              </option>
            );
          } else {
            return (
              <option key={cnClass.id} value={cnClass.cnClass}>
                {cnClass.cnClass}
              </option>
            );
          }
        })}
      </>
    );
  }
};

export default FormConcreteSelectOption;
