import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import JSXBoard from "./Board";
import { log, jsClasses } from "../../../utils/Utils";
import { createSectionRectanglePoints } from "./RectanglePoints";
import { useGfxContext } from "../../../context/GfxContext";
import { drawRectangleDimensionLines } from "./RectangleDimensionLines";
import { drawValues } from "./RectangleValues";
import { drawTendons } from "./RectangleTendons";

const RectangleMain = () => {
  /** Show log in console if true */
  const showLogs = false;
  /** Class name for log */
  const cls = jsClasses.RectangleMain;

  const { complexDrawData } = useGfxContext();

  let pointVisibile = true;
  let showLabel = false;

  const boardRef = useRef(); // Referencja do tablicy
  const xOffsetRef = useRef(0); // Punk początkowy do rysowania
  const points = useRef([0, 0, 0, 0]); // Tablica punktów rys. dynamicznego

  const b = useRef();
  const h = useRef();
  const tendon_info = useRef();

  useEffect(() => {
    initView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log(cls, "Data for draw", complexDrawData, showLogs);
    if (!complexDrawData.b.isNaN && !complexDrawData.h.isNaN) {
      b.current = complexDrawData.b[0];
      h.current = complexDrawData.h[0];
      tendon_info.current = complexDrawData.tendon_info;
    }

    if (h.current > 0.1) {
      updateBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complexDrawData]);

  const initView = () => {
    log(cls, "Inicjacja ComplexDrawDoubleTeeMain", boardRef.current, showLogs);
    if (boardRef.current !== undefined) {
      createSectionRectanglePoints(
        boardRef.current,
        xOffsetRef,
        points,
        b,
        h,
        pointVisibile,
        showLabel
      );

      drawRectangleDimensionLines(boardRef.current, points);
      createOutsideOutline();
      drawValues(boardRef.current, h, b, points);
      updateBoard();
    }
  };

  const gfx = (board) => {
    boardRef.current = board;
  };

  /**
   * Rysuje obrys zewnętrzny bryły.
   */
  const createOutsideOutline = () => {
    log(cls, "Tablic punktów", points.current, showLogs);

    boardRef.current.create(
      "polygon",
      [
        ...points.current        
      ],
      {
        borders: { strokeWidth: 5, strokeColor: "black" },
        fillOpacity: 1,
        fillColor: "#cdfee0",
      }
    );
  };

  /**
   * Uaktualnia zawartość tablicy
   */
  const updateBoard = () => {
    if (boardRef.current !== undefined) {
      log(cls, "Update board: ", points, showLogs);
      boardRef.current.fullUpdate();
      boardRef.current.zoomElements([points.current[0], points.current[2]]);

      if (b.current !== undefined && h.current !== undefined) {
        const tendonsProps = {
          b: b.current,
          h: h.current,
          tendon_info: tendon_info.current,
        };
        drawTendons(boardRef.current, tendonsProps, points.current);
      }
    }
  };

  return (
    <Container>
      <JSXBoard
        logic={gfx}
        boardAttributes={{
          axis: false,
          showCopyright: false,
          keepAspectRatio: true
        }}
        style={{
          border: "1px solid black",
        }}
      />
    </Container>
  );
};

export default RectangleMain;
