import { roundNumber, getZoomFactorForLines, getZoomFactorForTexts } from "../../utils/Utils";

const DoubleDrawXeff = () => {};

/**
 * Rysujne linie wymierowe oraz tekst dla wartości Xeff
 * @param {JSXBoard} board
 * @param {Xeff UseRef} x_eff
 * @param {JSXPoint} pA
 * @param {JSXPoint} pB
 * @param {Boolean} x_effVisibility
 */
export function drawXeff(board, x_eff, pA, pB, x_effVisibility) {
  drawXeffLines(board, x_eff, pA, pB, x_effVisibility);
  draw_Xeff_Text(board, x_eff, pA, pB, x_effVisibility);
}

/**
 * Rysuje tekst dla wartości Xeff
 * @param {JSXBoard} board
 * @param {Xeff UseRef} x_eff
 * @param {JSXPoint} pB
 * @param {Boolean} x_effVisibility
 */
function draw_Xeff_Text(board, x_eff, pA, pB, x_effVisibility) {
  board.create(
    "text",
    [
      function () {
        return pB.X() + getZoomFactorForTexts(pA.Y() + pB.X());;
      },
      function () {
        return pB.Y() - x_eff.current;
      },
      function () {
        return "Xeff = " + roundNumber(x_eff.current, 2) + " m";
      },
    ],
    {
      fontSize: 20,
      rotate: 90,
      display: "internal",
      visible: function () {
        return x_effVisibility.current;
      },
    }
  );
}

/**
 * Rysuje linie wymirowe dla Xeff
 * @param {JSXBoard} board
 * @param {Xeff UseRef} x_eff
 * @param {JSXPoint} pA
 * @param {JSXPoint} pB
 * @param {Boolean} x_effVisibility
 */
function drawXeffLines(board, x_eff, pA, pB, x_effVisibility) {
  var p0 = board.create(
    "point",
    [
      function () {
        return 0;
      },

      function () {
        return pA.Y() - x_eff.current;
      },
    ],
    {
      withLabel: false,
      size: 0,
    }
  );

  var p1 = board.create(
    "point",
    [
      function () {
        return pB.X();
      },

      function () {
        return pB.Y() - x_eff.current;
      },
    ],
    {
      withLabel: false,
      size: 0,
    }
  );

  var p2 = board.create(
    "point",
    [
      function () {
        return pB.X() + getZoomFactorForLines(pA.Y() + pB.X());;
      },

      function () {
        return pB.Y() - x_eff.current;
      },
    ],
    {
      withLabel: false,
      size: 0,
    }
  );

  var p3 = board.create(
    "point",
    [
      function () {
        return pB.X();
      },

      function () {
        return pB.Y();
      },
    ],
    {
      withLabel: false,
      size: 0,
    }
  );

  var p4 = board.create(
    "point",
    [
      function () {
        return pB.X() + getZoomFactorForLines(pA.Y() + pB.X());;
      },

      function () {
        return pB.Y();
      },
    ],
    {
      withLabel: false,
      size: 0,
    }
  );

  board.create("segment", [p2, p4], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
    visible: function () {
      return x_effVisibility.current;
    },
  });

  board.create("segment", [p1, p2], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
    visible: function () {
      return x_effVisibility.current;
    },
  });

  board.create("segment", [p3, p4], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
    visible: function () {
      return x_effVisibility.current;
    },
  });

  board.create("segment", [p0, p1], {
    strokeColor: "#911515",
    strokeWidth: 3,
    highlightStrokeWidth: 5,
    dash: 3,
    visible: function () {
      return x_effVisibility.current;
    },
  });
}

export default DoubleDrawXeff;
