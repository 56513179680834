import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import JSXBoard from "../../JSXBoard";

import { useGfxContext } from "../../context/GfxContext";

import { drawDimensionLines } from "./TsecDrawDimensionLines";
import { drawDimensionTexts } from "./TsecDrawTexts";
import { drawDimensionRebars } from "./TsecDrawRebars";

import { log, jsClasses } from "../../utils/Utils";

/** Show log in console if true */
const showLogs = true;
/** Class name for log */
const cls = jsClasses.JSXdrawT;

let pointVisibile = true;
let showLabel = false;

const JSXdrawT = () => {
  /* Referencja do tablicy */
  const boardRef = useRef();

  const b = useRef();
  const c = useRef();
  const b_eff = useRef();
  const h = useRef();
  const h_sl = useRef();
  const fi = useRef();
  const fi_opp = useRef();
  const fi_s = useRef();

  const xOffsetRef = useRef(0);

  const pAref = useRef();
  const pBref = useRef();
  const pCref = useRef();
  const pDref = useRef();
  const pEref = useRef();
  const pFref = useRef();
  const pGref = useRef();
  const pHref = useRef();

  const no_of_bars = useRef(0);
  const no_of_opp_bars = useRef(0);

  // const { setTDrawDataFromSliders } = useGlobalContext();
  const { tDrawData } = useGfxContext();

  useEffect(() => {
    log(cls, "Board", boardRef.current, showLogs);
    if (boardRef.current !== undefined) {
      createPoints();
      createDraw();
      updateBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log(cls, "Tdraw data", tDrawData, showLogs);
    b.current = tDrawData.b;
    c.current = tDrawData.c;
    b_eff.current = tDrawData.b_eff;
    h.current = tDrawData.h;
    h_sl.current = tDrawData.h_sl;
    no_of_bars.current = tDrawData.ns1;
    no_of_opp_bars.current = tDrawData.ns2;
    fi.current = tDrawData.fi;
    fi_opp.current = tDrawData.fi_opp;
    fi_s.current = tDrawData.fi_s;

    updateBoard();

    drawDimensionRebars(
      boardRef.current,
      no_of_bars,
      no_of_opp_bars,
      fi,
      fi_opp,
      fi_s,
      b,
      b_eff,
      c,
      pAref.current,
      pBref.current,
      pCref.current,
      pFref.current
    );

    updateBoard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tDrawData]);

  const updateBoard = () => {
    if (boardRef.current !== undefined) {
      log(cls, "refB", b.current, showLogs);
      log(cls, "refH", h.current, showLogs);
      boardRef.current.fullUpdate();
      boardRef.current.zoomElements([
        pAref.current,
        pBref.current,
        pCref.current,
        pDref.current,
        pEref.current,
        pFref.current,
        pGref.current,
        pHref.current,
      ]);
      log(cls, "pBx", pBref.current.X(), showLogs);
      log(cls, "pBy", pBref.current.Y(), showLogs);
    }
  };

  /** Długość ramienia T */
  var tVal = function () {
    return (b_eff.current - b.current) / 2;
  };

  const createPoints = () => {
    pAref.current = boardRef.current.create(
      "point",
      [
        xOffsetRef.current,
        function () {
          return h.current + h_sl.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );

    pBref.current = boardRef.current.create(
      "point",
      [
        function () {
          return pAref.current.X() + b_eff.current;
        },
        function () {
          return h.current + h_sl.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );

    pCref.current = boardRef.current.create(
      "point",
      [
        function () {
          return pAref.current.X() + b_eff.current;
        },
        function () {
          return h.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );

    pDref.current = boardRef.current.create(
      "point",
      [
        function () {
          return pAref.current.X() + b_eff.current - tVal();
        },
        function () {
          return h.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );

    pEref.current = boardRef.current.create(
      "point",
      [
        function () {
          return pAref.current.X() + b_eff.current - tVal();
        },
        0,
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );

    pFref.current = boardRef.current.create(
      "point",
      [
        function () {
          return pAref.current.X() + b_eff.current - tVal() - b.current;
        },
        0,
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );

    pGref.current = boardRef.current.create(
      "point",
      [
        function () {
          return pAref.current.X() + b_eff.current - tVal() - b.current;
        },
        function () {
          return h.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );

    pHref.current = boardRef.current.create(
      "point",
      [
        xOffsetRef.current,
        function () {
          return h.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );
  };

  const gfx = (board) => {
    boardRef.current = board;
  };

  const createDraw = () => {
    boardRef.current.create(
      "polygon",
      [
        pAref.current,
        pBref.current,
        pCref.current,
        pDref.current,
        pEref.current,
        pFref.current,
        pGref.current,
        pHref.current,
      ],
      {
        borders: { strokeWidth: 5, strokeColor: "black" },
        fillOpacity: 1,
        fillColor: "#cdfee0",
      }
    );

    drawDimensionLines(
      boardRef.current,
      pAref.current,
      pBref.current,
      pCref.current,
      pEref.current,
      pFref.current,
      pHref.current
    );

    drawDimensionTexts(
      boardRef.current,
      b_eff,
      h,
      h_sl,
      b,
      pAref.current,
      pBref.current,
      pCref.current,
      pEref.current,
      pFref.current,
      pHref.current
    );
  };

  return (
    <Container>
      <JSXBoard
        logic={gfx}
        boardAttributes={{
          axis: false,
          boundingbox: [-0.1, 2, 2, -0.25],
          showCopyright: false,
        }}
        style={{
          border: "1px solid black",
        }}
      />
    </Container>
  );
};

export default JSXdrawT;
