import { setupTendonsDistances, getCirclePoint } from "../../TendonsDrawCommon";
import { calcTypes, circleComponentPointType } from "../../../utils/StringsConst";

var tendons = []; // Zawiera cięgna (obiekty Circle bliblioteki JSXGraph)
var insidePoints = []; // Zawiera punkty środka dla obiektu Circle
var outsidePoints = []; // Zawiera punkty krańcowe obiektu Circle
var circlePointsArrays = []; // Przechowuje tablicę tablic zawierających punkty insidePoint & outsidePoint obiektu Circle

const TENDON_RADIUS = 0.045; // Promień cięgna (metry)
const TENDON_DIAMETER = TENDON_RADIUS * 2; // Średnica cięgna (metry)

const DoubleTeeTendons = () => {};

export function drawTendons(board, props, points) {
  board.suspendUpdate();
  board.removeObject(tendons);
  board.removeObject(insidePoints);
  board.removeObject(outsidePoints);
  tendons = [];
  insidePoints = [];
  outsidePoints = [];
  circlePointsArrays = [];
  draw(board, props, points);
  board.unsuspendUpdate();
}

function draw(board, props, points) {
  let tendonsArray = props.tendon_info;
  let tendonsRowCount = tendonsArray[0];
  // Przechowuje odległości między cięgnami, element tablicy odpowiada numerowi rzędu cięgien - el[3] tj. rząd cięgien nr 3
  // TODO: Do poprawy z powodu źle rysującego się cięgna 1 i ostatniego jeżeli jest ich > 1, konieczność implementacji tablicy przechowującej tablice zawieriące
  // współrzędne cięgien
  let distanceBtwTendons = [];

  for (let i = 1; i < tendonsRowCount + 1; i++) {
    let tendonsCount = tendonsArray[i][1]; // Ilość cięgien
    let yOffset = tendonsArray[i][0]; // Odległość cięgna od spodu przekroju, punk początkowy rysowania w osi Y

    /* Sprawdzenie czy położenie cięgn jest w strefie wysokości h3  */
    if (points[7].Y() + yOffset > points[11].Y()) {
      setupTendonsDistances(
        points,
        tendonsCount,
        distanceBtwTendons,
        i,
        1,
        0,
        TENDON_DIAMETER
      );

      /* Sprawdzenie czy położenie cięgn jest w strefie wysokości h2  */
    } else if (points[7].Y() + yOffset > points[8].Y()) {
      setupTendonsDistances(
        points,
        tendonsCount,
        distanceBtwTendons,
        i,
        4,
        9,
        TENDON_DIAMETER
      );

      /* Położenie cięgien jest w strefie wyskości h1 */
    } else {
      setupTendonsDistances(
        points,
        tendonsCount,
        distanceBtwTendons,
        i,
        6,
        7,
        TENDON_DIAMETER
      );
    }

    for (let j = 0; j < tendonsCount; j++) {
      var insidePoint = getCirclePoint(
        board,
        distanceBtwTendons,
        points,
        yOffset,
        i,
        j,
        circleComponentPointType.INSIDE_POINT,
        TENDON_DIAMETER,
        TENDON_RADIUS,
        calcTypes.DOUBLE_TEE_TYPE_CALC
      );
      var outsidePoint = getCirclePoint(
        board,
        distanceBtwTendons,
        points,
        yOffset,
        i,
        j,
        circleComponentPointType.OUTSIDE_POINT,
        TENDON_DIAMETER,
        TENDON_RADIUS,
        calcTypes.DOUBLE_TEE_TYPE_CALC
      );

      var arr = [insidePoint, outsidePoint];
      circlePointsArrays.push(arr);

      insidePoints.push(insidePoint);
      outsidePoints.push(outsidePoint);
    }
  }

  for (let x = 0; x < circlePointsArrays.length; x++) {
    var tendonCircle = board.create("circle", circlePointsArrays[x], {
      strokeColor: "blue",
      fillColor: "blue",
    });

    tendons.push(tendonCircle);
  }
}

export default DoubleTeeTendons;
