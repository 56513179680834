import { roundNumber, getZoomFactorForTexts } from "../../utils/Utils";

const SingleDrawTexts = () => {};

export function drawDimensionTexts(board, hValue, bValue, cValue, pA, pB, pC) {
  draw_H_Text(board, pA, pB, hValue);
  draw_B_Text(board, pA, pB, bValue);
  draw_C_Text(board, pA, pB, pC, cValue)
}

/**
 * Rysuje wymiar c na tablicy
 * @param {Board} board Tablica JSXGraph
 * @param {Point} pA Referencja do punkta A
 * @param {Point} hValue Referencja do wartości h
 */
 function draw_C_Text(board, pA, pB, pC, cValue) {
  board.create(
    "text",
    [
      function () {
        return pC.X() + getZoomFactorForTexts(pA.Y() + pB.X());
      },
      function () {
        return pC.Y();
      },
      function () {
        return "c = " + roundNumber(cValue.current, 2) + " mm";
      },
    ],
    { fontSize: 20, rotate: 90, display: "internal" }
  );
}

/**
 * Rysuje wymiar h na tablicy
 * @param {Board} board Tablica JSXGraph
 * @param {Point} pA Referencja do punkta A
 * @param {Point} hValue Referencja do wartości h
 */
function draw_H_Text(board, pA, pB, hValue) {
  board.create(
    "text",
    [
      function () {
        return pA.X() - getZoomFactorForTexts(pA.Y() + pB.X());
      },
      function () {
        return pA.Y() / 2;
      },
      function () {
        return "h = " + roundNumber(hValue.current, 2) + " m";
      },
    ],
    { fontSize: 20, rotate: 90, display: "internal" }
  );
}

/**
 * Rysuje wymiar b na tablicy
 * @param {Board} board Tablica JSXGraph
 * @param {Point} pA Referencja do punkta A
 * @param {Point} pB Referencja do punktu B
 * @param {Point} bValue Referencja do wartości b
 */
function draw_B_Text(board, pA, pB, bValue) {
  board.create(
    "text",
    [
      function () {
        return (pB.X() - pA.X()) / 2 - 0.08;
      },
      function () {
        return pA.Y() + getZoomFactorForTexts(pA.Y() + pB.X());
      },
      function () {
        return "b = " + roundNumber(bValue.current, 2) + " m";
      },
    ],
    { fontSize: 20 }
  );
}

export default SingleDrawTexts;
