/**
 *  Forma umożliwiająca wpisanie 3 wartości dla wymiaru 'b'
 */

/* eslint-disable eqeqeq */
import React, { useRef, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { jsClasses, log } from "../../utils/Utils";
import { sectionLabelCaptions, calcTypes } from "../../utils/StringsConst";

/** Show log in console if true, class name for log */
const showLogs = false;
const cls = jsClasses.FormMultipleHvalueInput;

const FormMultipleHvalueInput = (props) => {
  const valueA = useRef(props.defaultValues[0]);
  const valueB = useRef(props.defaultValues[1]);
  const valueC = useRef(props.defaultValues[2]);
  const labelsCaptions = useRef(sectionLabelCaptions.BOX_H)

  const setupValueA = (value) => {
    valueA.current = value;
    props.setH([value, valueB.current, valueC.current]);
  };

  const setupValueB = (value) => {
    valueB.current = value;
    props.setH([valueA.current, value, valueC.current]);
  };

  const setupValueC = (value) => {
    valueC.current = value;
    props.setH([valueA.current, valueB.current, value]);
  };

  useEffect(() => {
    if (props.sectionType === calcTypes.BOX_TYPE_CALC) {
      labelsCaptions.current = sectionLabelCaptions.BOX_H;
    } else if (props.sectionType === calcTypes.DOUBLE_TEE_TYPE_CALC){
      labelsCaptions.current = sectionLabelCaptions.DOUBLE_TEE_H;
    } else if (props.sectionType === calcTypes.TEE_TYPE_CALC){
      labelsCaptions.current = sectionLabelCaptions.TEE_H;
    } else if (props.sectionType === calcTypes.RECTANGLE_TYPE_CALC){
      labelsCaptions.current = sectionLabelCaptions.RECTANGLE_H;
    }

  }, [props.sectionType])

  useEffect(() => {
    if (props.numberOfInputs == 3) {
      setupValueA(props.defaultValues[0]);
      setupValueB(props.defaultValues[1]);
      setupValueC(props.defaultValues[2]);
    } else if (props.numberOfInputs == 2) {
      setupValueA(props.defaultValues[0]);
      setupValueB(props.defaultValues[1]);
      setupValueC(0);
    } else if (props.numberOfInputs == 1) {
      setupValueA(props.defaultValues[0]);
      setupValueC(0);
      setupValueB(0);
    }
    log (cls, "Zmiana - numbers of intput", {props, valueA, valueB, valueC}, showLogs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.numberOfInputs]);

  useEffect(() => {
    log(cls, "Default values", props.defaultValues, showLogs)
    valueA.current = props.defaultValues[0];
    valueB.current = props.defaultValues[1];
    valueC.current = props.defaultValues[2];
  }, [props.defaultValues])

  return (
    <>
      <h6>Height 'h' [m]:</h6>
      {props.numberOfInputs == 3 && (
        <Row>
          <Col>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueA(parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Label>{labelsCaptions.current[0]}</Form.Label>
              <Form.Control
                id="inputH1"
                type="number"
                placeholder={valueA.current}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueB(parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Label>{labelsCaptions.current[1]}</Form.Label>
              <Form.Control
                id="inputH2"
                type="number"
                placeholder={valueB.current}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueC(parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Label>{labelsCaptions.current[2]}</Form.Label>
              <Form.Control
                id="inputH3"
                type="number"
                placeholder={valueC.current}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {props.numberOfInputs == 2 && (
        <Row>
          <Col>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                valueC.current = 0;
                setupValueA(parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Label>{labelsCaptions.current[0]}</Form.Label>
              <Form.Control
                id="inputH1"
                type="number"
                placeholder={valueA.current}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                valueC.current = 0;
                setupValueB(parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Label>{labelsCaptions.current[1]}</Form.Label>
              <Form.Control
                id="inputH2"
                type="number"
                placeholder={valueB.current}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {props.numberOfInputs == 1 && (
        <Row>
          <Col>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                valueB.current = 0;
                valueC.current = 0;
                setupValueA(parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Label>{labelsCaptions.current[0]}</Form.Label>
              <Form.Control
                id="inputH1"
                type="number"
                placeholder={valueA.current}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FormMultipleHvalueInput;
