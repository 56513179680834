import {} from "mdb-react-ui-kit";
import React from "react";

const LoginComponent = () => {
  return (
    <>
      <div className="d-flex" style={{ height: "3vh" }} />
      <div className="d-flex">
        <div className="w-100"></div>
        <div className="flex-shrink-1">
          <a href="/login">
            <img
              style={{ height: "27px", width: "183px" }}
              src="login.png"
              alt="Sample"
              href="/login"
            />
          </a>
        </div>
        
      </div>
    </>
  );
};

export default LoginComponent;
