/**
 * Kontekst przechowujący dane dla dynamicznych rysunków.
 */

import React, { useState, useContext } from "react";

const AppContext = React.createContext();

const GfxProvider = ({ children }) => {
  const [singleCalcBoard, setSingleCalcBoard] = useState();
  const [brdPointA, setBrdPointA] = useState();
  const [brdPointB, setBrdPointB] = useState();
  /* Data for draw of calc T */
  const [tDrawData, setTDrawData] = useState({
    b: 0,
    h: 0,
    b_eff: 0,
    h_sl: 0,
    c: 0,
    fi: 0,
    fi_opp: 0,
    fi_s: 0,
    ns1: 0,
    ns2: 0,
  });

  /* Data for draw single reinf. calc */
  const [singleDrawData, setSingleDrawData] = useState({
    b: 0,
    h: 0,
    c: 0,
    fi: 0,
    fi_s: 0,
    x_eff: 0,
    no_of_bars: 0,
  });

  /* Data for draw double reinf. calc */
  const [doubleDrawData, setDoubleDrawData] = useState({
    b: 0,
    h: 0,
    c: 0,
    fi: 0,
    fi_s: 0,
    x_eff: 0,
    fi_opp: 0,
    no_of_bars: 0,
    no_of_opp_bars: 0,
  });

  /* Data for draw calc single dimensionig */
  const [singleDimensioningDrawData, setSingleDimensioningDrawData] = useState({
    b: 0,
    h: 0,
    c: 0,
    fi: 0,
    fi_s: 0,
    fi_opp: 0,
    no_of_bars: 0,
    no_of_opp_bars: 0,
  });

  /* Data for draw complex calc */
  const [complexDrawData, setComplexDrawData] = useState({
    b: [0, 0, 0],
    h: [0, 0, 0],
    tendon_info: [1, [0.2, 3, 20, 150]],
  })

  const [complexResultDrawData, setComplexResultDrawData] = useState({

  })

  return (
    <AppContext.Provider
      value={{
        /* Data for draw calc single dimensionig */
        singleDimensioningDrawData,
        setSingleDimensioningDrawData,
        /* Data for draw of double calc */
        doubleDrawData,
        setDoubleDrawData,
        /* Data for draw of calc T */
        tDrawData,
        setTDrawData,
        /* Data for draw of single reinf. calc */
        singleDrawData,
        setSingleDrawData,
        /* Data for draw complex calc */
        complexDrawData,
        setComplexDrawData,
        /* Board & points */
        singleCalcBoard,
        setSingleCalcBoard,
        brdPointA,
        setBrdPointA,
        brdPointB,
        setBrdPointB,
        /* Dane do rysowania wyników w kompleksowym kalkulatorze */
        complexResultDrawData, 
        setComplexResultDrawData
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook
export const useGfxContext = () => {
  return useContext(AppContext);
};

export { AppContext, GfxProvider };
