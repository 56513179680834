import React, { useEffect, useState } from "react";
import axios from "axios";

import { useGlobalContext } from "./../../Context";
import { useGfxContext } from "../../context/GfxContext";
import { jsClasses, log } from "../../utils/Utils";

/** Show log in console if true */
const showLogs = true;
/** Class name for log */
const cls = jsClasses.ComplexApi;

const ComplexApi = () => {
  const {
    setModalWaitText,
    setModalWaitShow,
    complexDataApi,
    // setComplexDataFromApi,
    apiTrigger,
    setApiTrigger,
  } = useGlobalContext();

  const { setComplexResultDrawData } = useGfxContext();

  const [complexResp, setComplexResp] = useState();

  useEffect(() => {
    log(cls, "Reciving data to send for API", complexDataApi, showLogs);
    log(cls, "apiTrigger", apiTrigger, showLogs);
    if (
      apiTrigger.isButtonPressed &&
      apiTrigger.isNoErrors &&
      !apiTrigger.isWaitForAction
    ) {
      initSendData(complexDataApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complexDataApi, apiTrigger]);

  async function initSendData() {
    setModalWaitShow(true);
    setModalWaitText("Please wait...");

    let clConc = complexDataApi.cl_conc.replace("/", "_");

    await axios
      .post(
        "https://django-civil-85.herokuapp.com/api/civil_calcs/axial_and_bending",
        {
          name: complexDataApi.name,
          b: complexDataApi.b,
          h: complexDataApi.h,
          cl_conc: clConc,
          cl_steel: complexDataApi.cl_steel,
          c: complexDataApi.c,
          fi: complexDataApi.fi,
          fi_s: complexDataApi.fi_s,
          fi_opp: complexDataApi.fi_opp,
          nl_reinf_top: complexDataApi.nl_reinf_top,
          nl_reinf_bottom: complexDataApi.nl_reinf_bottom,
          m_sd: complexDataApi.m_sd,
          n_sd: complexDataApi.n_sd,
          tendon_info: complexDataApi.tendon_info,
          prestr_ef_in_mn: complexDataApi.prestr_ef_in_mn,
          level_of_tension: complexDataApi.level_of_tension,
          r_k_tendons: complexDataApi.r_k_tendons,
        }
      )
      .then(
        (response) => {
          log(cls, "Responde from API", response.data, showLogs);
          setModalWaitShow(false);
          const concrete_strain_lower_limit =
            response.data["concrete strain lower limit"];
          const concrete_strain_upper_limit =
            response.data["concrete strain upper limit"];
          const concrete_stress_lower_limit =
            response.data["concrete stress lower limit"];
          const concrete_stress_upper_limit =
            response.data["concrete stress upper limit"];
          const draw_concrete_strain_lower_limit =
            response.data["draw concrete strain lower limit"];
          const draw_concrete_strain_upper_limit =
            response.data["draw concrete strain upper limit"];
          const draw_concrete_stress_lower_limit =
            response.data["draw concrete stress lower limit"];
          const draw_concrete_stress_upper_limit =
            response.data["draw concrete stress upper limit"];
          const draw_steel_strain_lower_limit =
            response.data["draw steel strain lower limit"];
          const draw_steel_strain_upper_limit =
            response.data["draw steel strain upper limit"];
          const draw_steel_stress_lower_limit =
            response.data["draw steel stress lower limit"];
          const draw_steel_stress_upper_limit =
            response.data["draw steel stress upper limit"];
          const draw_tendons_strain_lower_limit =
            response.data["draw tendons strain lower limit"];
          const draw_tendons_strain_upper_limit =
            response.data["draw tendons strain upper limit"];
          const draw_tendons_stress_lower_limit =
            response.data["draw tendons stress lower limit"];
          const draw_tendons_stress_upper_limit =
            response.data["draw tendons stress upper limit"];
          const steel_strain_lower_limit =
            response.data["steel strain lower limit"];
          const steel_strain_upper_limit =
            response.data["steel strain upper limit"];
          const steel_stress_lower_limit =
            response.data["steel stress lower limit"];
          const steel_stress_upper_limit =
            response.data["steel stress upper limit"];
          const strains_in_concrete = response.data["strains in concrete"];
          const strains_in_steel = response.data["strains in steel"];
          const strains_in_tendons = response.data["strains in tendons"];
          const stress_in_concrete = response.data["stress in concrete"];
          const stress_in_steel = response.data["stress in steel"];
          const stress_in_tendons = response.data["stress in tendons"];
          const tendons_strain_lower_limit =
            response.data["tendons strain lower limit"];
          const tendons_strain_upper_limit =
            response.data["tendons strain upper limit"];
          const tendons_stress_lower_limit =
            response.data["tendons stress lower limit"];
          const tendons_stress_upper_limit =
            response.data["tendons stress upper limit"];

          setComplexResp({
            concrete_strain_lower_limit,
            concrete_strain_upper_limit,
            concrete_stress_lower_limit,
            concrete_stress_upper_limit,
            draw_concrete_strain_lower_limit,
            draw_concrete_strain_upper_limit,
            draw_concrete_stress_lower_limit,
            draw_concrete_stress_upper_limit,
            draw_steel_strain_lower_limit,
            draw_steel_strain_upper_limit,
            draw_steel_stress_lower_limit,
            draw_steel_stress_upper_limit,
            draw_tendons_strain_lower_limit,
            draw_tendons_strain_upper_limit,
            draw_tendons_stress_lower_limit,
            draw_tendons_stress_upper_limit,
            steel_strain_lower_limit,
            steel_strain_upper_limit,
            steel_stress_lower_limit,
            steel_stress_upper_limit,
            strains_in_concrete,
            strains_in_steel,
            strains_in_tendons,
            stress_in_concrete,
            stress_in_steel,
            stress_in_tendons,
            tendons_strain_lower_limit,
            tendons_strain_upper_limit,
            tendons_stress_lower_limit,
            tendons_stress_upper_limit,
          });

          setComplexResultDrawData({
            concrete_strain_lower_limit,
            concrete_strain_upper_limit,
            concrete_stress_lower_limit,
            concrete_stress_upper_limit,
            draw_concrete_strain_lower_limit,
            draw_concrete_strain_upper_limit,
            draw_concrete_stress_lower_limit,
            draw_concrete_stress_upper_limit,
            draw_steel_strain_lower_limit,
            draw_steel_strain_upper_limit,
            draw_steel_stress_lower_limit,
            draw_steel_stress_upper_limit,
            draw_tendons_strain_lower_limit,
            draw_tendons_strain_upper_limit,
            draw_tendons_stress_lower_limit,
            draw_tendons_stress_upper_limit,
            steel_strain_lower_limit,
            steel_strain_upper_limit,
            steel_stress_lower_limit,
            steel_stress_upper_limit,
            strains_in_concrete,
            strains_in_steel,
            strains_in_tendons,
            stress_in_concrete,
            stress_in_steel,
            stress_in_tendons,
            tendons_strain_lower_limit,
            tendons_strain_upper_limit,
            tendons_stress_lower_limit,
            tendons_stress_upper_limit,
          });

          log(cls, "Parsed Responde from API", complexResp, showLogs);
        },
        (error) => {
          log(cls, "ERROR", error, true);
        }
      );
    resetApiTrigger();
  }

  const resetApiTrigger = () => {
    setApiTrigger({
      isButtonPressed: false,
      isNoErrors: false,
      isWaitForAction: false,
    });
    log(cls, "Api Trigger", apiTrigger, showLogs);
  };

  return <></>;
};

export default ComplexApi;
