import { roundNumber, getZoomFactorForTexts } from "../../../utils/Utils";

const DoubleTeeValues = () => {};

const FONT_SIZE = 14;

export function drawValues(board, b1, b2, b3, h1, h2, h3, points) {
  draw_h1_Text(board, points, h1);
  draw_h2_Text(board, points, h2);
  draw_h3_Text(board, points, h3);
  draw_b1_Text(board, points, b1);
  draw_b2_Text(board, points, b2);
  draw_b3_Text(board, points, b3);
}

/**
 * Rysuje wymiar h1 na tablicy
 */
function draw_h1_Text(board, points, h1) {
  board.create(
    "text",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return points.current[7].Y() + 0.1;
      },
      function () {
        return roundNumber(h1.current, 2);
      },
    ],
    { fontSize: FONT_SIZE, rotate: 90, display: "internal" }
  );
}

/**
 * Rysuje wymiar h2 na tablicy
 */
function draw_h2_Text(board, points, h2) {
  board.create(
    "text",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return (
          points.current[9].Y() +
          ((points.current[10].Y() - points.current[9].Y()) / 2 - 0.3)
        );
      },
      function () {
        return "h2 = " + roundNumber(h2.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE, rotate: 90, display: "internal" }
  );
}

/**
 * Rysuje wymiar h3 na tablicy
 */
function draw_h3_Text(board, points, h3) {
  board.create(
    "text",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return points.current[11].Y() + 0.1;
      },
      function () {
        return roundNumber(h3.current, 2);
      },
    ],
    { fontSize: FONT_SIZE, rotate: 90, display: "internal" }
  );
}

/**
 * Rysuje wymiar b1 na tablicy
 */
function draw_b1_Text(board, points, b) {
  board.create(
    "text",
    [
      function () {
        return (points.current[7].X() + points.current[6].X()) / 2 - 0.08;
      },
      function () {
        return (
          points.current[7].Y() -
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return "b1 = " + roundNumber(b.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE }
  );
}

/**
 * Rysuje wymiar b2 na tablicy
 */
function draw_b2_Text(board, points, b2) {
  board.create(
    "text",
    [
      function () {
        return points.current[4].X() + 0.08;
      },
      function () {
        return (
          points.current[10].Y() -
          (points.current[10].Y() - points.current[9].Y()) / 2
        );
      },
      function () {
        return "b2 = " + roundNumber(b2.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE }
  );
}

/**
 * Rysuje wymiar b3 na tablicy
 */
function draw_b3_Text(board, points, b_eff) {
  board.create(
    "text",
    [
      function () {
        return (points.current[1].X() - points.current[0].X()) / 2 - 0.08;
      },
      function () {
        return (
          points.current[0].Y() +
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return "b3 = " + roundNumber(b_eff.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE }
  );
}

export default DoubleTeeValues;
