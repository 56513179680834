/**
 * Component containing selection options (steel classes) for 'Form.Select' component.
 */

import React from "react";

import { log, jsClasses } from "../../utils/Utils";

/** Show log in console if true */
const showLogs = false;
/** Class name for log */
const cls = jsClasses.FormSteelSelectOption;

const FormSteelSelectOption = ({ steel }) => {
  if (steel === undefined) {
    log(cls, "UNDEFINED", steel, showLogs);
    return (
      <>
        <option value="1">Bst500s</option>
        <option value="1">B500SP</option>
      </>
    );
  } else {
    log(cls, "NOT UNDEFINED", steel, showLogs);
    return (
      <>
        {steel.map((stClass) => {
          return (
            <option key={stClass.id} value={stClass.stClass}>
              {stClass.stClass}
            </option>
          );
        })}
      </>
    );
  }
};

export default FormSteelSelectOption;
