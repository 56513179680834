import React from "react";

import { Form, Col } from "react-bootstrap";
import FormConcreteSelectOption from "./FormConcreteSelectOption";
import FormSteelSelectOption from "./FormSteelSelectOption";
import { calcTypes } from "../../utils/StringsConst";

export function FormGroupNameInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="name"
      onChange={(e) => {
        props.setName(e.target.value);
      }}
    >
      <Form.Label>Cross section label:</Form.Label>
      <Form.Control type="text" placeholder="My first cross sect" />
    </Form.Group>
  );
}

export function FormBvalueInput(props) {
  return (
    <>
      <h6>Width 'b' [m]:</h6>
      <Col>
        <Form.Group
          className="mb-3"
          onChange={(e) => {
            props.setB(parseFloat(e.target.value.replace(",", ".")));
          }}
        >
          <Form.Control id="inputB" type="number" placeholder="0.5" />
        </Form.Group>
      </Col>
      <Col>
        <Form.Range
          id="bRange"
          min="0.1"
          max="20"
          step="0.1"
          value={props.b}
          onChange={(changeEvent) => {
            props.setB(parseFloat(changeEvent.target.value.replace(",", ".")));
            document.getElementById("inputB").value =
              changeEvent.target.value.replace(",", ".");
          }}
        />
      </Col>
    </>
  );
}

export function FormHvalueInput(props) {
  return (
    <>
      <h6>Height 'h' [m]:</h6>
      <Col>
        <Form.Group
          className="mb-3"
          onChange={(e) => {
            props.setH(parseFloat(e.target.value.replace(",", ".")));
          }}
        >
          <Form.Control id="inputH" type="number" placeholder="1.4" />
        </Form.Group>
      </Col>
      <Col>
        <Form.Range
          id="hRange"
          min="0.1"
          max="20"
          step="0.1"
          value={props.h}
          onChange={(changeEvent) => {
            props.setH(parseFloat(changeEvent.target.value.replace(",", ".")));
            document.getElementById("inputH").value =
              changeEvent.target.value.replace(",", ".");
          }}
        />
      </Col>
    </>
  );
}

export function FormCvalueInput(props) {
  return (
    <>
      <h6>Concrete cover 'c' [mm]: </h6>
      <Col>
        <Form.Group
          className="mb-3"
          onChange={(e) => {
            props.setC(parseFloat(e.target.value.replace(",", ".")));
          }}
        >
          <Form.Control id="inputC" type="number" placeholder="30" />
        </Form.Group>
      </Col>
      <Col>
        <Form.Range
          id="cRange"
          min="1"
          max="100"
          step="1"
          value={props.c}
          onChange={(changeEvent) => {
            props.setC(parseFloat(changeEvent.target.value.replace(",", ".")));
            document.getElementById("inputC").value =
              changeEvent.target.value.replace(",", ".");
          }}
        />
      </Col>
    </>
  );
}

export function FormNoOfRebarsValueInput(props) {
  return (
    <>
      <h6>{props.title}</h6>
      <Col>
        <Form.Group
          className="mb-3"
          controlId="no_of_bars"
          onChange={(e) => {
            props.setNo_of_bars(parseFloat(e.target.value.replace(",", ".")));
          }}
        >
          <Form.Control type="number" placeholder={props.placeholder} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Range
          id="no_of_barsRange"
          min="1"
          max="100"
          step="1"
          value={props.no_of_bars}
          onChange={(changeEvent) => {
            props.setNo_of_bars(
              parseFloat(changeEvent.target.value.replace(",", "."))
            );
            document.getElementById("no_of_bars").value =
              changeEvent.target.value.replace(",", ".");
          }}
        />
      </Col>
    </>
  );
}

/**
 * Forma wspólna: Msd [kNm]:
 */
export function FormMsdValueImput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="m_sd"
      onChange={(e) => {
        props.setM_sd(parseFloat(e.target.value.replace(",", ".")));
      }}
    >
      <Form.Label>Msd [kNm]: </Form.Label>
      <Form.Control type="number" placeholder="3000" />
    </Form.Group>
  );
}

/**
 * Forma wspólna: Msd [MNm]:
 */
export function FormMsdValueMnmInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="m_sd_mnm"
      onChange={(e) => {
        props.setM_sd(parseFloat(e.target.value.replace(",", ".")));
      }}
    >
      <Form.Label>Msd [MNm]: </Form.Label>
      <Form.Control type="number" placeholder="-4" />
    </Form.Group>
  );
}

/**
 * Forma wspólna: Nsd [kN]:
 */
export function FormNsdValueImput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="n_sd"
      onChange={(e) => {
        props.setN_sd(parseFloat(e.target.value.replace(",", ".")));
      }}
    >
      <Form.Label>Nsd [kN]: </Form.Label>
      <Form.Control type="number" placeholder="3000" />
    </Form.Group>
  );
}

/**
 * Forma wspólna: Nsd [kN]:
 */
export function FormNsdValueMnmInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="n_sd_mnm"
      onChange={(e) => {
        props.setN_sd(parseFloat(e.target.value.replace(",", ".")));
      }}
    >
      <Form.Label>Nsd [MN]: </Form.Label>
      <Form.Control type="number" placeholder="-2" />
    </Form.Group>
  );
}

/**
 * Forma wspólna: 'Diameter of stirrups Øs [mm]:'
 */
export function FormFiSValueInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="fi_s"
      onChange={(e) => {
        props.setFi_s(parseFloat(e.target.value.replace(",", ".")));
      }}
    >
      <Form.Label>
        Diameter of stirrups Ø<sub>s</sub> [mm]:
      </Form.Label>
      <Form.Select aria-label="Default select example" placeholder="12">
        <option value="32">32</option>
        <option value="25">25</option>
        <option value="20">20</option>
        <option value="18">18</option>
        <option value="16">16</option>
        <option value="12" selected>
          12
        </option>
        <option value="10">10</option>
        <option value="8">8</option>
        <option value="6">6</option>
      </Form.Select>
    </Form.Group>
  );
}

/**
 * Forma wspólna: Main reinforc. diameter Ø [mm]:
 */
export function FormFiValueInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="fi"
      onChange={(e) => {
        props.setFi(parseFloat(e.target.value.replace(",", ".")));
      }}
    >
      <Form.Label>Main reinforc. diameter Ø [mm]: </Form.Label>
      <Form.Select aria-label="Default select example" placeholder="32">
        <option value="32">32</option>
        <option value="25">25</option>
        <option value="20">20</option>
        <option value="18">18</option>
        <option value="16">16</option>
        <option value="12">12</option>
        <option value="10">10</option>
        <option value="8">8</option>
        <option value="6">6</option>
      </Form.Select>
    </Form.Group>
  );
}

/**
 * Forma wspólna: Opp. reinforc. diameter Ø [mm]:
 */
export function FormFiOppValueInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="fi_opp"
      onChange={(e) => {
        props.setFi_opp(parseFloat(e.target.value.replace(",", ".")));
      }}
    >
      <Form.Label>Opp. reinforc. diameter Ø [mm]: </Form.Label>
      <Form.Select aria-label="Default select example" placeholder="32">
        <option value="32">32</option>
        <option value="25">25</option>
        <option value="20">20</option>
        <option value="18">18</option>
        <option value="16">16</option>
        <option value="12">12</option>
        <option value="10">10</option>
        <option value="8">8</option>
        <option value="6">6</option>
      </Form.Select>
    </Form.Group>
  );
}

/**
 * Forma wspólna: Concrete class
 */
export function FormClConcValueInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="concrete_class"
      onChange={(e) => {
        props.setCl_conc(e.target.value);
      }}
    >
      <Form.Label>Concrete class :</Form.Label>
      <Form.Select aria-label="Default select example">
        <FormConcreteSelectOption concrete={props.concrete} />
      </Form.Select>
    </Form.Group>
  );
}

/**
 * Forma wspólna: Steel class
 */
export function FormClSteelValueInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="steel_type"
      onChange={(e) => {
        props.setCl_steel(e.target.value);
      }}
    >
      <Form.Label>Steel class :</Form.Label>
      <Form.Select aria-label="Default select example">
        <FormSteelSelectOption steel={props.steel} />
      </Form.Select>
    </Form.Group>
  );
}

/**
 * Forma wspólna: 'Select the type of section'
 */
export function FormTypeOfSectionInput(props) {
  const onChangeEvent = (e) => {
    // eslint-disable-next-line eqeqeq
    if (e.target.value == 4) {
      props.setSectionType(calcTypes.BOX_TYPE_CALC);
      props.setNumberOfInputs(3);
    }
    // eslint-disable-next-line eqeqeq
    if (e.target.value == 3) {
      props.setSectionType(calcTypes.DOUBLE_TEE_TYPE_CALC);
      props.setNumberOfInputs(3);
    }
    // eslint-disable-next-line eqeqeq
    if (e.target.value == 2) {
      props.setSectionType(calcTypes.TEE_TYPE_CALC);
      props.setNumberOfInputs(2);
    }
    // eslint-disable-next-line eqeqeq
    if (e.target.value == 1) {
      props.setSectionType(calcTypes.RECTANGLE_TYPE_CALC);
      props.setNumberOfInputs(1);
    }
  };

  return (
    <Form.Group
      className="mb-3"
      controlId="section_type"
      onChange={(e) => {
        onChangeEvent(e);
      }}
    >
      <Form.Label>Select the type of section:</Form.Label>
      <Form.Select aria-label="Default select example">
        <option value={4} selected>
          Box
        </option>
        <option value={3}>Double T</option>
        <option value={2}>T</option>
        <option value={1}>Rectangle</option>
      </Form.Select>
    </Form.Group>
  );
}

/**
 * Forma wspólna: Number of rows of bottom reinforcement:
 */
export function FormNumberRowsBottomReinfInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="bottom_rows"
      onChange={(e) => {
        props.setMainBarsRows(e.target.value);
      }}
    >
      <Form.Label>Number of rows of bottom reinforcement: </Form.Label>
      <Form.Select aria-label="Default select example" placeholder="3">
        <option value="1" selected>
          1
        </option>
        <option value="2">2</option>
        <option value="3">3</option>
      </Form.Select>
    </Form.Group>
  );
}

/**
 * Forma wspólna: Number of rows of opp. reinforcement:
 */
export function FormNumberRowsOppReinfInput(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="opp_rows"
      onChange={(e) => {
        props.setOppBarsRows(e.target.value);
      }}
    >
      <Form.Label>Number of rows of opp. reinforcement: </Form.Label>
      <Form.Select aria-label="Default select example" placeholder="3">
        <option value="1" selected>
          1
        </option>
        <option value="2">2</option>
        <option value="3">3</option>
      </Form.Select>
    </Form.Group>
  );
}

export function FormMultipleBvalueInput(props) {
  return (
    <>
      <h6>Width 'b' [m]:</h6>
      <Col>
        <Form.Group
          className="mb-3"
          onChange={(e) => {
            props.setB(parseFloat(e.target.value.replace(",", ".")));
          }}
        >
          <Form.Control id="inputB" type="number" placeholder="0.5" />
        </Form.Group>
      </Col>
      <Col>
        <Form.Range
          id="bRange"
          min="0.1"
          max="20"
          step="0.1"
          value={props.b}
          onChange={(changeEvent) => {
            props.setB(parseFloat(changeEvent.target.value.replace(",", ".")));
            document.getElementById("inputB").value =
              changeEvent.target.value.replace(",", ".");
          }}
        />
      </Col>
    </>
  );
}

export function FormPercentOfTensionInput(props) {
  return (
    <>
      <h6>
        % of tension in the tendons (due to tension and losses) to the strength
        of the tendons:
      </h6>
      <Col>
        <Form.Group
          className="mb-3"
          onChange={(e) => {
            props.setLevel_of_tension(
              parseFloat(e.target.value.replace(",", "."))
            );
          }}
        >
          <Form.Control
            id="level_of_tension"
            type="number"
            placeholder={props.level_of_tension}
          />
        </Form.Group>
      </Col>
    </>
  );
}

export function FormCharStrSteelInput(props) {
  return (
    <>
      <h6>characteristic strength of steel of the tendons [MPa]:</h6>
      <Col>
        <Form.Group
          className="mb-3"
          onChange={(e) => {
            props.setR_k_tendons(parseFloat(e.target.value.replace(",", ".")));
          }}
        >
          <Form.Control id="r_k_tendons" type="number" placeholder="1860" />
        </Form.Group>
      </Col>
    </>
  );
}
