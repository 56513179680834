import { roundNumber, getZoomFactorForTexts } from "../../../utils/Utils";

const TeeValues = () => {};

const FONT_SIZE = 14;

export function drawValues(board, h, h_sl, b, b_eff, points) {
  draw_H_Text(board, points, h);
  draw_B_Text(board, points, b);
  draw_Beff_Text(board, points, b_eff);
  draw_Hsl_Text(board, points, h_sl);
}

/**
 * Wyświetla wymiar h_sl
 */
function draw_Hsl_Text(board, points, h_sl) {
  board.create(
    "text",
    [
      function () {
        return (
          points.current[1].X() +
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return (points.current[2].Y() + points.current[1].Y()) / 2 - 0.08;
      },
      function () {
        return "hsl = " + roundNumber(h_sl.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE, rotate: 90, display: "internal" }
  );
}

/**
 * Wyświetla wymiar b_eff
 */
function draw_Beff_Text(board, points, b_eff) {
  board.create(
    "text",
    [
      function () {
        return (points.current[1].X() - points.current[0].X()) / 2 - 0.08;
      },
      function () {
        return (
          points.current[0].Y() +
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return "b_e_f_f = " + roundNumber(b_eff.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE }
  );
}

/**
 * Rysuje wymiar h na tablicy
 */
function draw_H_Text(board, points, h) {
  board.create(
    "text",
    [
      function () {
        return (
          points.current[7].X() -
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return points.current[7].Y() / 2;
      },
      function () {
        return "h = " + roundNumber(h.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE, rotate: 90, display: "internal" }
  );
}

/**
 * Rysuje wymiar b na tablicy
 */
function draw_B_Text(board, points, b) {
  board.create(
    "text",
    [
      function () {
        return (points.current[5].X() + points.current[4].X()) / 2 - 0.08;
      },
      function () {
        return (
          points.current[5].Y() -
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return "b = " + roundNumber(b.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE }
  );
}

export default TeeValues;
