import { MDBTypography } from "mdb-react-ui-kit";
import React from "react";

const FunctionsDescComponent = () => {
  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex text-white">
          <MDBTypography variant="h1">Open source testing</MDBTypography>
        </div>
        <div className="d-flex text-white">
          <MDBTypography variant="h1">Always up-to date</MDBTypography>
        </div>
        <div className="d-flex text-white">
          <MDBTypography variant="h3">
            Constantly evolving. AI supported backend
          </MDBTypography>
        </div>
      </div>
    </>
  );
};

export default FunctionsDescComponent;
