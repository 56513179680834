import React from "react";
import NavigationBar from "../../NavigationBar";

const CreepShrinkCalcView = () => {
  return (
    <>
    <NavigationBar />
    <div class='container'>
      <h3>Creep and shrinkage computations</h3>
    </div>
    
    <div class='container-md'>
      <div class='row'>
        <div class='col'>

          <form>
              <div class='mb-3'>
                <label class='form-label' for='name'>Unique member label:</label>
                <input placeholder='Creep and shrinkage computations' type='text' id='name' class='form-control'/>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='superstructure_member'>Superstructure member:</label>
                <select aria-label='Default select example' class='form-select' id='superstructure_member'>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='concrete_class'>Concrete class:</label>
                <select aria-label='Default select example' class='form-select' id='concrete_class'>
                  <option value='C12/15'>C12/15</option>
                  <option value='C16/20'>C16/20</option>
                  <option value='C20/25'>C20/25</option>
                  <option value='C25/30'>C25/30</option>
                  <option value='C30/37' selected>C30/37</option>
                  <option value='C35/45'>C35/45</option>
                  <option value='C40/50'>C40/50</option>
                  <option value='C45/55'>C45/55</option>
                  <option value='C50/60'>C50/60</option>
                </select>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='concrete_cross_sectional_area'>Concrete cross-sectional area A<sub>c</sub> [m<sup>2</sup>]:</label>
                <input placeholder='0.5' min='0.01' max='200' type='number'  id='concrete_cross_sectional_area' class='form-control'/>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='cross_section'>Cross-section perimeter length exposed to drying U [m]:</label>
                <input placeholder='2.5' min='0.01' max='100' type='number'  id='cross_section' class='form-control'/>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='age_of_concrete_at_loading'>Age of concrete at loading t<sub>0</sub> [days]:</label>
                <input placeholder='28' min='0' max='73000' type='number'  id='age_of_concrete_at_loading' class='form-control'/>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='ambient_temperature'>Ambient temperature [<sup>0</sup>C]:</label>
                <input placeholder='15' min='0' max='50' type='number'  id='ambient_temperature' class='form-control'/>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='relative_humidity'>Relative humidity [%]:</label>
                <input placeholder='70' min='0' max='100' type='number'  id='relative_humidity' class='form-control'/>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='cement_type'>Cement type: </label>
                <select aria-label='Default select example' class='form-select' id='cement_type'>
                  <option value='class_S'>class_S</option>
                  <option value='class_N'>class_N</option>
                  <option value='class_R'>class_R</option>
                </select>
              </div>
              <div class='mb-3'>
                <label class='form-label' for='age_of_concrete_at_end_of_curing'>Age of concrete at end of curing t<sub>s</sub> [days]:</label>
                <input placeholder='7' min='1' max='100' type='number'  id='age_of_concrete_at_end_of_curing' class='form-control'/>
              </div>

             
          </form>
          </div>
        </div>
      </div>




  
    </>
  );
};

export default CreepShrinkCalcView;
