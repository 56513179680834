import React, { useState, useEffect } from "react";
import axios from "axios";

import { useApiMaterialContext } from "../context/ApiMaterialContext";
import { log, jsClasses } from "./Utils";

/** Show log in console if true */
const showLogs = false;
/** Class name for log */
const cls = jsClasses.MaterialsApi;

const MaterialsApi = () => {
  const { setMaterialClasses } = useApiMaterialContext();

  /* JSON Api data */
  const [concrete, setConcrete] = useState([]);
  const [steel, setSteel] = useState([]);

  /* Wywyołanie zapytania API w celu pobrania danych zawierających klasy stali i betonu */
  useEffect(() => {
    initSendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log(cls, "Backend respond", { steel, concrete }, showLogs);
    const materialsFromApi = { steel: steel, concrete: concrete };
    setMaterialClasses(materialsFromApi);
    log(cls, "Material from Api Object", materialsFromApi, showLogs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steel, concrete]);

  async function initSendData() {
    await axios
      .get(
        "https://django-civil-85.herokuapp.com/api/civil_calcs/get_material_data"
      )
      .then(
        (response) => {
          log(
            cls,
            "API response: ",
            response.data.current_steel_class,
            showLogs
          );
          log(
            cls,
            "API response: ",
            response.data.current_conc_class,
            showLogs
          );

          var steelArray = [];
          var id = 0;
          response.data.current_steel_class.map((st) => {
            steelArray.push({ id: id, stClass: st });
            id++;
            return 0;
          });

          var concreteArray = [];
          id = 0;
          response.data.current_conc_class.map((c) => {
            concreteArray.push({ id: id, cnClass: c });
            id++;
            return 0;
          });

          setSteel(steelArray);
          setConcrete(concreteArray);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  return <></>;
};

export default MaterialsApi;
