/**
 * Klasa tworzy punkty do narysowania dynamicznego rysunku dla sekcji DOBULE T
 */

const RectanglePoints = () => {
  return <></>;
};

/**
 * Tworzy punkty do narysowania obrysu przekroju sekcji RECTANGLE kompleksowego kalkulatora
 * @param {*} boardRef Referencja do tablicy JSXGraph
 * @param {*} xOffsetRef odniesienie do rysunku
 * @param {*} points Tablica punktów
 * @param {*} b wymiar b
 * @param {*} h wymair h
 * @param {*} pointVisibile Flaga widoczności punktów
 * @param {*} showLabel Flaga widoczności etkiet punktów
 */
export function createSectionRectanglePoints(
  boardRef,
  xOffsetRef,
  points,
  b,
  h,
  pointVisibile,
  showLabel
) {

  const pointProperties = {
    visible: pointVisibile,
    withLabel: showLabel,
    size: 1,
    fillColor: "black",
    strokeColor: "black",
    strokeWidth: 0,
  };

  /** PUNKT 00 */
  points.current[0] = boardRef.create(
    "point",
    [
      xOffsetRef.current,
      function () {
        return h.current;
      },
    ],
    pointProperties
  );
  /** PUNKT 01 */
  points.current[1] = boardRef.create(
    "point",
    [
      function () {
        return points.current[0].X() + b.current;
      },
      function () {
        return points.current[0].Y();
      },
    ],
    pointProperties
  );
  /** PUNKT 02 */
  points.current[2] = boardRef.create(
    "point",
    [
      function () {
        return points.current[1].X();
      },
      function () {
        return points.current[1].Y() - h.current;
      },
    ],
    pointProperties
  );
  /** PUNKT 03 */
  points.current[3] = boardRef.create(
    "point",
    [
      function () {
        return points.current[2].X() - b.current;
      },
      function () {
        return points.current[2].Y();
      },
    ],
    pointProperties
  );
}

export default RectanglePoints;
