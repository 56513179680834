import { roundNumber, getZoomFactorForTexts } from "../../utils/Utils";

const TsecDrawTexts = () => {};

export function drawDimensionTexts(
  board,
  b_eff,
  h,
  h_sl,
  b,
  pA,
  pB,
  pC,
  pE,
  pF,
  pH
) {
  draw_Beff_Text(board, b_eff, pA, pB);
  draw_H_Text(board, h, pA, pB, pF, pH);
  draw_Hsl_Text(board, h_sl, pA, pB, pC);
  draw_B_Text(board, b, pA, pB, pE, pF);
}

/**
 * Rysuje tekst wartości B_eff
 * @param {*} board
 * @param {*} b
 * @param {*} pA
 * @param {*} pB
 */
function draw_B_Text(board, b, pA, pB, pE, pF) {
  board.create(
    "text",
    [
      function () {
        return (pF.X() + pE.X()) / 2 - 0.08;
      },
      function () {
        return pF.Y() - getZoomFactorForTexts(pA.Y() + pB.X());
      },
      function () {
        return "b = " + roundNumber(b.current, 2) + " m";
      },
    ],
    { fontSize: 20 }
  );
}

/**
 * Draw B_eff value near by dimension line
 * @param {*} board
 * @param {*} b_eff
 * @param {*} pA
 * @param {*} pB
 */
function draw_Beff_Text(board, b_eff, pA, pB) {
  board.create(
    "text",
    [
      function () {
        return (pB.X() - pA.X()) / 2 - 0.08;
      },
      function () {
        return pA.Y() + getZoomFactorForTexts(pA.Y() + pB.X());
      },
      function () {
        return "b_e_f_f = " + roundNumber(b_eff.current, 2) + " m";
      },
    ],
    { fontSize: 20 }
  );
}

/**
 * Draw H value near by dimension line
 * @param {*} board
 * @param {*} h
 * @param {*} pA
 * @param {*} pF
 */
function draw_H_Text(board, h, pA, pB, pF, pH) {
  board.create(
    "text",
    [
      function () {
        return pH.X() - getZoomFactorForTexts(pA.Y() + pB.X());
      },
      function () {
        return (pH.Y() - pF.Y()) / 2 - 0.08;
      },
      function () {
        return "h = " + roundNumber(h.current, 2) + " m";
      },
    ],
    { fontSize: 20, rotate: 90, display: "internal" }
  );
}

/**
 * Draw H_sl value near by dimension line
 * @param {*} board
 * @param {*} sliderH
 * @param {*} pA
 * @param {*} pF
 */
function draw_Hsl_Text(board, h_sl, pA, pB, pC) {
  board.create(
    "text",
    [
      function () {
        return pB.X() + getZoomFactorForTexts(pA.Y() + pB.X());
      },
      function () {
        return (pC.Y() + pB.Y()) / 2 - 0.08;
      },
      function () {
        return "hsl = " + roundNumber(h_sl.current, 2) + " m";
      },
    ],
    { fontSize: 20, rotate: 90, display: "internal" }
  );
}

export default TsecDrawTexts;
