import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col } from "react-bootstrap";

import NavigationBar from "../../NavigationBar";
import SingleDimReinfResultView from "./SingleDimReinfResultView";
import SingleDimReinfErrHandler from "./SingleDimReinfErrHandler";
import SingleDimReinfApi from "./SingleDimReinfApi";

import MainDrawReinfDim from "../../graphics/dynamicDrawReinfDim/MainDrawReinfDim";

import { useGlobalContext } from "../../Context";
import { useGfxContext } from "../../context/GfxContext";
import { useApiMaterialContext } from "../../context/ApiMaterialContext";

import {
  FormGroupNameInput,
  FormBvalueInput,
  FormHvalueInput,
  FormCvalueInput,
  FormFiSValueInput,
  FormFiValueInput,
  FormFiOppValueInput,
  FormMsdValueImput,
  FormClConcValueInput,
  FormClSteelValueInput,
} from "../commons/CommonFormGroups";

import MaterialsApi from "../../utils/MaterialsApi";

import { pageTitles } from "../../utils/StringsConst";
import { log, jsClasses } from "../../utils/Utils";
import { MDBBtn } from "mdb-react-ui-kit";

/** Show log in console if true, class name for log */
const showLogs = false;
const cls = jsClasses.SingleDimReinfCalcView;

const SingleDimReinfCalcView = () => {
  const [name, setName] = useState("My first cross sect");
  const [b, setB] = useState(0.5);
  const [h, setH] = useState(1.4);
  const [cl_conc, setCl_conc] = useState("C30_37");
  const [cl_steel, setCl_steel] = useState("BSt500S");
  const [c, setC] = useState(30);
  const [fi, setFi] = useState(32);
  const [fi_s, setFi_s] = useState(12);
  const [fi_opp, setFi_opp] = useState(32);
  const [m_sd, setM_sd] = useState(3000);
  /* JSON Api data */
  const [as1, setAs1] = useState(0);
  const [ns1, setNs1] = useState(0);
  const [as2, setAs2] = useState(0);
  const [ns2, setNs2] = useState(0);
  const [remark, setRemark] = useState("no remarks");
  /* Klasy betonu i stali z API*/
  const [steel, setSteel] = useState([]);
  const [concrete, setConcrete] = useState([]);

  const {
    setSingleDimensioningData,
    apiTrigger,
    setApiTrigger,
    singleDimensioningDataFromApi,
  } = useGlobalContext();

  const { materialClasses } = useApiMaterialContext();
  const { setSingleDimensioningDrawData } = useGfxContext();

  useEffect(() => {
    log(cls, "Data from Material API: ", materialClasses, showLogs);
    setSteel(materialClasses.steel);
    setConcrete(materialClasses.concrete);
  }, [materialClasses]);

  useEffect(() => {
    log(cls, "Reciving data from API", singleDimensioningDataFromApi, showLogs);

    setAs1(singleDimensioningDataFromApi.as1);
    setNs1(singleDimensioningDataFromApi.ns1);
    setAs2(singleDimensioningDataFromApi.as2);
    setNs2(singleDimensioningDataFromApi.ns2);
    setRemark(singleDimensioningDataFromApi.remark);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDimensioningDataFromApi]);

  useEffect(() => {
    setSingleDimensioningDrawData({
      b: b,
      h: h,
      c: c,
      fi: fi,
      fi_s: fi_s,
      fi_opp: fi_opp,
      no_of_bars: ns1,
      no_of_opp_bars: ns2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b, h, c, fi, fi_s, fi_opp, ns1, ns2]);

  const sendDataToApi = (event) => {
    event.preventDefault();
    const dataToSend = {
      name,
      b,
      h,
      cl_conc,
      cl_steel,
      c,
      fi,
      fi_s,
      fi_opp,
      m_sd,
    };
    log(cls, "Sending data to API", dataToSend, showLogs);
    setupDataModel(true);
    setSingleDimensioningData(dataToSend);
  };

  /**
   *
   * @param {*} props
   */
  const setupDataModel = (props) => {
    const isButtonPressed = props;
    const isNoErrors = apiTrigger.isNoErrors;
    const isWaitForAction = apiTrigger.isWaitForAction;

    const dataModel = { isButtonPressed, isNoErrors, isWaitForAction };

    setApiTrigger(dataModel);
  };

  return (
    <>
      <NavigationBar />
      <MaterialsApi />
      <SingleDimReinfErrHandler />
      <SingleDimReinfApi />
      <Container fluid>
        <Row>
          <Col xxl={1}></Col>
          <Col>
            <h3>{pageTitles.SINGLE_DIMENSIONING_CALC_TITLE}</h3>
            <Row>
              <Col xxl={5} xl={5} lg={4}>
                <Form>
                  <FormGroupNameInput setName={setName}></FormGroupNameInput>
                  <Row>
                    <FormBvalueInput b={b} setB={setB}></FormBvalueInput>
                  </Row>
                  <Row>
                    <FormHvalueInput h={h} setH={setH}></FormHvalueInput>
                  </Row>
                  <Row>
                    <Col>
                      <FormClConcValueInput
                        setCl_conc={setCl_conc}
                        concrete={concrete}
                      ></FormClConcValueInput>
                    </Col>
                    <Col>
                      <FormClSteelValueInput
                        setCl_steel={setCl_steel}
                        steel={steel}
                      ></FormClSteelValueInput>
                    </Col>
                  </Row>
                  <Row>
                    <FormCvalueInput c={c} setC={setC}></FormCvalueInput>
                  </Row>
                  <Row>
                    <Col>
                      <FormFiSValueInput setFi_s={setFi_s}></FormFiSValueInput>
                    </Col>
                    <Col>
                      <FormMsdValueImput setM_sd={setM_sd}></FormMsdValueImput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormFiValueInput setFi={setFi}></FormFiValueInput>
                    </Col>
                    <Col>
                      <FormFiOppValueInput
                        setFi_opp={setFi_opp}
                      ></FormFiOppValueInput>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col>
                <MainDrawReinfDim />
                <br></br>
                <Container float="md">
                <MDBBtn
                    color="dark"
                    type="button"
                    className="btn btn-primary"
                    aria-controls="example-collapse-text"
                    aria-expanded={true}
                    onClick={(event) => {
                      sendDataToApi(event);
                    }}
                  >
                    Calculate
                  </MDBBtn>
                </Container>
              </Col>
            </Row>
            <br></br>
            <SingleDimReinfResultView
              isCollapseOpen={true}
              as1={as1}
              ns1={ns1}
              as2={as2}
              ns2={ns2}
              remark={remark}
            />
          </Col>
          <Col xxl={1}></Col>
        </Row>
      </Container>
    </>
  );
};

export default SingleDimReinfCalcView;
