/**
 * Context storing material data from API (concrete and steel classes).
 */

import React, { useState, useContext } from "react";

const AppContext = React.createContext();

const ApiMaterialProvider = ({ children }) => {
  /* Array storing classes of concrete and steel */
  const [materialClasses, setMaterialClasses] = useState({});

  return (
    <AppContext.Provider
      value={{
        materialClasses,
        setMaterialClasses,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook
export const useApiMaterialContext = () => {
  return useContext(AppContext);
};

export { AppContext, ApiMaterialProvider };
