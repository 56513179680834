import React from "react";

const TitleComponent = () => {

  return (
    <img
      style={{ width: "75vh" }}
      src="lpls_back.png"
      className="img-fluid"
      alt="Sample"
    />
  );
};

export default TitleComponent;
