/**
 * Klasa zawiera stałe tekstowe dla nazw pól, podstron itp
 */

const StringsConst = () => {};

/**
 * Stałe dla tytułów stron poszczególnych kalkulatorów, oraz dla pozycji w menu listy rozwijanej
 */
export const pageTitles = {
  SINGLE_CALC_TITLE: "Single reinforced, rectangular section bending capacity",
  DOUBLE_CALC_TITLE: "Double reinforced, rectangular section bending capacity",
  SINGLE_DIMENSIONING_CALC_TITLE:
    "Rectangular section reinforcement dimensioning",
  TEE_CALC_TITLE: "T-section reinforcement dimensioning",
  COMPLEX_CALC_TITLE:
    "General calculator for complex sections loaded axially or eccentrically or by pure bending",
  CREEP_SHRINK:
    "Creep and shrink calculator"
};

/**
 * Typy kalkulatorów kompleksowego kalkulatora
 */
export const calcTypes = {
  BOX_TYPE_CALC: "boxTypeCalc",
  DOUBLE_TEE_TYPE_CALC: "doubleTeeTypeCalc",
  TEE_TYPE_CALC: "teeTypeCalc",
  RECTANGLE_TYPE_CALC: "rectangleTypeCalc",
};

/**
 * Typy punktów do rysowania komponentu CIRCLE biblioteki JSXGraph
 */
export const circleComponentPointType = {
  INSIDE_POINT: "INSIDE_POINT",
  OUTSIDE_POINT: "OUTSIDE_POINT",
};

/**
 * Opisy etykiet dla componentu:
 * @see FormMultipleBvalueInput
 * @link src\calcsViews\calcComplex\FormMultipleBvalueInput.js
 * @see FormMultipleHvalueInput
 * @link C:\repo\engclcfe\src\calcsViews\calcComplex\FormMultipleHvalueInput.js
 */
export const sectionLabelCaptions = {
  BOX_B: ["b1:", "b2:", "b3:"],
  BOX_H: ["h1:", "h2:", "h3:"],
  DOUBLE_TEE_B: ["b1:", "b2:", "b3:"],
  DOUBLE_TEE_H: ["h1:", "h2:", "h3:"],
  TEE_B: ["beff:", "b:", ""],
  TEE_H: ["h:", "hsl:", ""],
  RECTANGLE_B: ["b:", "", ""],
  RECTANGLE_H: ["h:", "", ""],
};

/**
 * Wartości domyślne dla sekcji kalkulatora kompleksowego.
 */
export class CalcSectionsDefaultValues {
  static RECTANGLE_H = new CalcSectionsDefaultValues(4.0);
  static RECTANGLE_B = new CalcSectionsDefaultValues(1.5);

  static TEE_H = new CalcSectionsDefaultValues(4.0);
  static TEE_H_SL = new CalcSectionsDefaultValues(0.4);
  static TEE_B = new CalcSectionsDefaultValues(3.5);
  static TEE_B_EFF = new CalcSectionsDefaultValues(1.2);

  static DOUBLE_TEE_B1 = new CalcSectionsDefaultValues(1.4);
  static DOUBLE_TEE_B2 = new CalcSectionsDefaultValues(0.4);
  static DOUBLE_TEE_B3 = new CalcSectionsDefaultValues(4.0);
  static DOUBLE_TEE_H1 = new CalcSectionsDefaultValues(0.5);
  static DOUBLE_TEE_H2 = new CalcSectionsDefaultValues(1.0);
  static DOUBLE_TEE_H3 = new CalcSectionsDefaultValues(0.3);

  static BOX_B1 = new CalcSectionsDefaultValues(4.8);
  static BOX_B2 = new CalcSectionsDefaultValues(0.9);
  static BOX_B3 = new CalcSectionsDefaultValues(12.2);
  static BOX_H1 = new CalcSectionsDefaultValues(0.35);
  static BOX_H2 = new CalcSectionsDefaultValues(2.35);
  static BOX_H3 = new CalcSectionsDefaultValues(0.3);

  constructor(value) {
    this.value = value;
  }
}

export default StringsConst;
