/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";

import { Container, Form, Button, Col, Row, Tabs, Tab } from "react-bootstrap";

import { useGlobalContext } from "./../../Context";
import { useGfxContext } from "../../context/GfxContext";
import { useApiMaterialContext } from "../../context/ApiMaterialContext";

import NavigationBar from "../../NavigationBar";
import FormPrestessingTendons from "./FormPrestessingTendons";
import FormNoOfMainBarsInput from "./FormNoOfMainBarsInput";
import FormNoOfOppBarsInput from "./FormNoOfOppBarsInput";
import FormMultipleBvalueInput from "./FormMultipleBvalueInput";
import FormMultipleHvalueInput from "./FormMultipleHvalueInput";
import ComplexAssumptions from "./ComplexAssumptions";
import ComplexErrHandler from "./ComplexErrHandler";
import ComplexApi from "./ComplexApi";
import MaterialsApi from "../../utils/MaterialsApi";

import {
  FormGroupNameInput,
  FormCvalueInput,
  FormFiSValueInput,
  FormFiValueInput,
  FormFiOppValueInput,
  FormClConcValueInput,
  FormClSteelValueInput,
  FormTypeOfSectionInput,
  FormNumberRowsBottomReinfInput,
  FormNumberRowsOppReinfInput,
  FormMsdValueMnmInput,
  FormNsdValueMnmInput,
  FormPercentOfTensionInput,
  FormCharStrSteelInput,
} from "../commons/CommonFormGroups";

import { log, jsClasses } from "../../utils/Utils";
import {
  pageTitles,
  calcTypes,
  CalcSectionsDefaultValues,
} from "../../utils/StringsConst";
import ComplexResultView from "./ComplexResultView";
import ComplexDrawBoxMain from "../../graphics/dynamicDrawComplex/figureDraw/BoxMain";
import ComplexDrawTeeMain from "../../graphics/dynamicDrawComplex/figureDraw/TeeMain";
import ComplexDrawDoubleTeeMain from "../../graphics/dynamicDrawComplex/figureDraw/DoubleTeeMain";
import ComplexDrawRectangleMain from "../../graphics/dynamicDrawComplex/figureDraw/RectangleMain";
import { MDBBtn } from "mdb-react-ui-kit";

/** Show log in console if true, class name for log */
const showLogs = true;
const cls = jsClasses.ComplexMain;

const ComplexMain = () => {
  const [name, setName] = useState("My first gen cross sect.");
  const [b, setB] = useState([
    CalcSectionsDefaultValues.BOX_B1.value,
    CalcSectionsDefaultValues.BOX_B2.value,
    CalcSectionsDefaultValues.BOX_B3.value,
  ]);
  const [h, setH] = useState([
    CalcSectionsDefaultValues.BOX_H1.value,
    CalcSectionsDefaultValues.BOX_H2.value,
    CalcSectionsDefaultValues.BOX_H3.value,
  ]);
  const [cl_conc, setCl_conc] = useState("C30_37");
  const [cl_steel, setCl_steel] = useState("BSt500S");
  const [c, setC] = useState(30);
  const [fi, setFi] = useState(32);
  const [fi_s, setFi_s] = useState(12);
  const [fi_opp, setFi_opp] = useState(32);
  const [nl_reinf_top, setNl_reinf_top] = useState([1, [20, 0, 0]]);
  const [nl_reinf_bottom, setNl_reinf_bottom] = useState([1, [10, 0, 0]]);
  const [m_sd, setM_sd] = useState(-4);
  const [n_sd, setN_sd] = useState(-2);
  const tendon_info = useRef([1, [0.2, 4, 19, 150]]);
  const [prestr_ef_in_mn, setPrestr_ef_in_mn] = useState(false);
  const [level_of_tension, setLevel_of_tension] = useState(70);
  const [r_k_tendons, setR_k_tendons] = useState(1860);

  const [numberOfInputs, setNumberOfInputs] = useState(3); // Ilość pól edycyjnych dla parametrów b i h
  const [sectionType, setSectionType] = useState(calcTypes.BOX_TYPE_CALC); // Typ sekcji do narysowania (Box, dobule T, T, prostokąt)
  const [mainBarsRows, setMainBarsRows] = useState(1);
  const [oppBarsRows, setOppBarsRows] = useState(1);

  const [percentOfTension, setPercentOfTension] = useState(100);
  /* Klasy betonu i stali z API*/
  const [steel, setSteel] = useState([]);
  const [concrete, setConcrete] = useState([]);

  const { setComplexDataApi, apiTrigger, setApiTrigger, complexDataFromApi } =
    useGlobalContext();

  const { materialClasses } = useApiMaterialContext();
  const { setComplexDrawData } = useGfxContext();

  const [activeTab, setActiveTab] = useState("parameters");

  useEffect(() => {
    log(cls, "Data from Material API: ", materialClasses, showLogs);
    setSteel(materialClasses.steel);
    setConcrete(materialClasses.concrete);
  }, [materialClasses]);

  useEffect(() => {
    log(cls, "Data from API: ", complexDataFromApi, showLogs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complexDataFromApi]);

  useEffect(() => {
    log(cls, "b, h Zmiana", { b, h }, showLogs);
    setComplexDrawData({
      b: b,
      h: h,
      tendon_info: tendon_info.current,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b, h]);

  /**
   * Reset wartości domyslnych formtaki
   */
  useEffect(() => {
    if (sectionType === calcTypes.TEE_TYPE_CALC) {
      document.getElementById("inputB1").value =
        CalcSectionsDefaultValues.TEE_B.value;
      document.getElementById("inputB2").value =
        CalcSectionsDefaultValues.TEE_B_EFF.value;
      document.getElementById("inputH1").value =
        CalcSectionsDefaultValues.TEE_H.value;
      document.getElementById("inputH2").value =
        CalcSectionsDefaultValues.TEE_H_SL.value;
      setB([
        CalcSectionsDefaultValues.TEE_B.value,
        CalcSectionsDefaultValues.TEE_B_EFF.value,
        0,
      ]);
      setH([
        CalcSectionsDefaultValues.TEE_H.value,
        CalcSectionsDefaultValues.TEE_H_SL.value,
        0,
      ]);
    } else if (sectionType === calcTypes.RECTANGLE_TYPE_CALC) {
      document.getElementById("inputB1").value =
        CalcSectionsDefaultValues.RECTANGLE_B.value;
      document.getElementById("inputH1").value =
        CalcSectionsDefaultValues.RECTANGLE_H.value;
      setB([CalcSectionsDefaultValues.RECTANGLE_B.value, 0, 0]);
      setH([CalcSectionsDefaultValues.RECTANGLE_H.value, 0, 0]);
    } else if (sectionType === calcTypes.DOUBLE_TEE_TYPE_CALC) {
      document.getElementById("inputB1").value =
        CalcSectionsDefaultValues.DOUBLE_TEE_B1.value;
      document.getElementById("inputB2").value =
        CalcSectionsDefaultValues.DOUBLE_TEE_B2.value;
      document.getElementById("inputB3").value =
        CalcSectionsDefaultValues.DOUBLE_TEE_B3.value;
      document.getElementById("inputH1").value =
        CalcSectionsDefaultValues.DOUBLE_TEE_H1.value;
      document.getElementById("inputH2").value =
        CalcSectionsDefaultValues.DOUBLE_TEE_H2.value;
      document.getElementById("inputH3").value =
        CalcSectionsDefaultValues.DOUBLE_TEE_H3.value;
      setB([
        CalcSectionsDefaultValues.DOUBLE_TEE_B1.value,
        CalcSectionsDefaultValues.DOUBLE_TEE_B2.value,
        CalcSectionsDefaultValues.DOUBLE_TEE_B3.value,
      ]);
      setH([
        CalcSectionsDefaultValues.DOUBLE_TEE_H1.value,
        CalcSectionsDefaultValues.DOUBLE_TEE_H2.value,
        CalcSectionsDefaultValues.DOUBLE_TEE_H3.value,
      ]);
    } else {
      document.getElementById("inputB1").value =
        CalcSectionsDefaultValues.BOX_B1.value;
      document.getElementById("inputB2").value =
        CalcSectionsDefaultValues.BOX_B2.value;
      document.getElementById("inputB3").value =
        CalcSectionsDefaultValues.BOX_B3.value;
      document.getElementById("inputH1").value =
        CalcSectionsDefaultValues.BOX_H1.value;
      document.getElementById("inputH2").value =
        CalcSectionsDefaultValues.BOX_H2.value;
      document.getElementById("inputH3").value =
        CalcSectionsDefaultValues.BOX_H3.value;
      setB([
        CalcSectionsDefaultValues.BOX_B1.value,
        CalcSectionsDefaultValues.BOX_B2.value,
        CalcSectionsDefaultValues.BOX_B3.value,
      ]);
      setH([
        CalcSectionsDefaultValues.BOX_H1.value,
        CalcSectionsDefaultValues.BOX_H2.value,
        CalcSectionsDefaultValues.BOX_H3.value,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionType]);

  const sendDataToApi = (event) => {

    const dataToSend = {
      name: name,
      b: b,
      h: h,
      cl_conc: cl_conc,
      cl_steel: cl_steel,
      c: c,
      fi: fi,
      fi_s: fi_s,
      fi_opp: fi_opp,
      nl_reinf_top: nl_reinf_top,
      nl_reinf_bottom: nl_reinf_bottom,
      m_sd: m_sd,
      n_sd: n_sd,
      tendon_info: tendon_info.current,
      prestr_ef_in_mn: prestr_ef_in_mn,
      level_of_tension: level_of_tension,
      r_k_tendons: r_k_tendons,
    };

    if (sectionType === calcTypes.TEE_TYPE_CALC) {
      const newB = [b[2], b[1], b[0]]
      const newH = [h[2], h[0], h[1]]

      dataToSend.b = newB;
      dataToSend.h = newH;  
    }

    log(cls, "Sending data for API: ", dataToSend, showLogs);

    setupDataModel(true);
    setComplexDataApi(dataToSend);
    event.preventDefault();
    activateTab("results");
  };

  /**
   *
   * @param {*} props
   */
  const setupDataModel = (props) => {
    const isButtonPressed = props;
    const isNoErrors = apiTrigger.isNoErrors;
    const isWaitForAction = apiTrigger.isWaitForAction;

    const dataModel = { isButtonPressed, isNoErrors, isWaitForAction };

    setApiTrigger(dataModel);
  };

  /**
   * Aktywuje zakładke rezulatów
   * @param {String} tab Nazwa klucza zakładki do aktywowania
   */
  const activateTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <NavigationBar />
      <MaterialsApi />
      <ComplexApi />
      <ComplexErrHandler />
      <Container>
        <h3>{pageTitles.COMPLEX_CALC_TITLE}</h3>
      </Container>
      <Container fluid>
        <Tabs
          defaultActiveKey={activeTab}
          id="uncontrolled-tab-example"
          activeKey={activeTab}
          onSelect={activateTab}
          className="mb-3"
          fill
        >
          <Tab eventKey="parameters" title="Parameters">
            <br></br>
            <Row>
              <Col>
                <Form>
                  <Row>
                    <Row>
                      <Col>
                        <FormGroupNameInput
                          setName={setName}
                        ></FormGroupNameInput>
                      </Col>
                      <Col>
                        <FormTypeOfSectionInput
                          setNumberOfInputs={setNumberOfInputs}
                          setSectionType={setSectionType}
                        ></FormTypeOfSectionInput>
                      </Col>
                    </Row>
                    <Row>
                      <FormMultipleBvalueInput
                        defaultValues={b}
                        numberOfInputs={numberOfInputs}
                        setB={setB}
                        sectionType={sectionType}
                      ></FormMultipleBvalueInput>
                    </Row>
                    <Row>
                      <FormMultipleHvalueInput
                        defaultValues={h}
                        numberOfInputs={numberOfInputs}
                        setH={setH}
                        sectionType={sectionType}
                      ></FormMultipleHvalueInput>
                    </Row>
                    <Col>
                      <Row>
                        <FormCvalueInput c={c} setC={setC}></FormCvalueInput>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormClConcValueInput
                        setCl_conc={setCl_conc}
                        concrete={concrete}
                      ></FormClConcValueInput>
                    </Col>
                    <Col>
                      <FormClSteelValueInput
                        setCl_steel={setCl_steel}
                        steel={steel}
                      ></FormClSteelValueInput>
                    </Col>
                    <Col>
                      <FormFiSValueInput setFi_s={setFi_s}></FormFiSValueInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormFiValueInput setFi={setFi}></FormFiValueInput>
                    </Col>
                    <Col>
                      <FormNumberRowsBottomReinfInput
                        setMainBarsRows={setMainBarsRows}
                      ></FormNumberRowsBottomReinfInput>
                    </Col>
                    <Col>
                      <FormFiOppValueInput
                        setFi_opp={setFi_opp}
                      ></FormFiOppValueInput>
                    </Col>
                    <Col>
                      <FormNumberRowsOppReinfInput
                        setOppBarsRows={setOppBarsRows}
                      ></FormNumberRowsOppReinfInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormNoOfMainBarsInput
                        numberOfRows={mainBarsRows}
                        setNl_reinf_bottom={setNl_reinf_bottom}
                      />
                    </Col>
                    <Col>
                      <FormNoOfOppBarsInput
                        numberOfRows={oppBarsRows}
                        setNl_reinf_top={setNl_reinf_top}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormMsdValueMnmInput
                        setM_sd={setM_sd}
                      ></FormMsdValueMnmInput>
                    </Col>
                    <Col>
                      <FormNsdValueMnmInput
                        setN_sd={setN_sd}
                      ></FormNsdValueMnmInput>
                    </Col>
                  </Row>
                  <Row>
                    <FormPrestessingTendons
                      prestr_ef_in_mn={prestr_ef_in_mn}
                      setPrestr_ef_in_mn={setPrestr_ef_in_mn}
                      setTendon_info={tendon_info}
                    ></FormPrestessingTendons>
                  </Row>
                  <Row>
                    <Col>
                      <FormPercentOfTensionInput
                        level_of_tension={level_of_tension}
                        setLevel_of_tension={setLevel_of_tension}
                      ></FormPercentOfTensionInput>
                    </Col>
                    <Col>
                      <FormCharStrSteelInput
                        setR_k_tendons={setR_k_tendons}
                      ></FormCharStrSteelInput>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col>
                {sectionType === calcTypes.BOX_TYPE_CALC && (
                  <ComplexDrawBoxMain />
                )}
                {sectionType === calcTypes.DOUBLE_TEE_TYPE_CALC && (
                  <ComplexDrawDoubleTeeMain />
                )}
                {sectionType === calcTypes.TEE_TYPE_CALC && (
                  <ComplexDrawTeeMain />
                )}
                {sectionType === calcTypes.RECTANGLE_TYPE_CALC && (
                  <ComplexDrawRectangleMain />
                )}
                <br />
                <ComplexAssumptions></ComplexAssumptions>
                <Container fluid="lg">
                  <Row>
                    <Col>
                      <MDBBtn
                        color="dark"
                        type="button"
                        className="btn btn-primary"
                        aria-controls="example-collapse-text"
                        aria-expanded={true}
                        onClick={(event) => {
                          sendDataToApi(event);
                        }}
                      >
                        Calculate
                      </MDBBtn>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <br></br>
          </Tab>
          <Tab eventKey="results" title="Results">
            <ComplexResultView
              isCollapseOpen={true}
              m_rd={0}
              ksi_eff={0}
              x_eff={0}
            />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default ComplexMain;
