import React, { useState, useEffect } from "react";
import { Container, Form, Col, Row } from "react-bootstrap";

import { useGlobalContext } from "./../../Context";
import { useGfxContext } from "../../context/GfxContext";
import { useApiMaterialContext } from "../../context/ApiMaterialContext";

import JSXdrawSingle from "../../graphics/dynamicDrawSingle/JSXdrawSingle";

import NavigationBar from "../../NavigationBar";
import SingleReinfConcreteResultView from "./SingleReinfConcreteResultView";
import SingleReinfConcreteErrHandler from "./SingleReinfConcreteErrHandler";
import SingleReinfConcreteApi from "./SingleReinfConcreteApi";
import MaterialsApi from "../../utils/MaterialsApi";

import {
  FormGroupNameInput,
  FormBvalueInput,
  FormHvalueInput,
  FormCvalueInput,
  FormNoOfRebarsValueInput,
  FormFiSValueInput,
  FormFiValueInput,
  FormClConcValueInput,
  FormClSteelValueInput,
} from "../commons/CommonFormGroups";

import { log, jsClasses } from "../../utils/Utils";
import { pageTitles } from "../../utils/StringsConst";
import { MDBBtn } from "mdb-react-ui-kit";

/** Show log in console if true, class name for log */
const showLogs = false;
const cls = jsClasses.SingleReinfConcreteCalcView;

const SingleReinfConcreteCalcView = () => {
  const [name, setName] = useState("My first cross sect");
  const [b, setB] = useState(0.5);
  const [h, setH] = useState(1.4);
  const [cl_conc, setCl_conc] = useState("C30_37");
  const [cl_steel, setCl_steel] = useState("BSt500S");
  const [c, setC] = useState(30);
  const [fi, setFi] = useState(32);
  const [no_of_bars, setNo_of_bars] = useState(8);
  const [fi_s, setFi_s] = useState(12);
  /* JSON Api data */
  const [m_rd, setM_rd] = useState(0);
  const [ksi_eff, setKsi_eff] = useState(0);
  const [x_eff, setX_eff] = useState(0);
  /* Klasy betonu i stali z API*/
  const [steel, setSteel] = useState([]);
  const [concrete, setConcrete] = useState([]);

  const {
    setSingleDimensioningData,
    apiTrigger,
    setApiTrigger,
    singleDimensioningDataFromApi,
  } = useGlobalContext();

  const { materialClasses } = useApiMaterialContext();
  const { setSingleDrawData } = useGfxContext();

  useEffect(() => {
    log(cls, "Data from Material API: ", materialClasses, showLogs);
    setSteel(materialClasses.steel);
    setConcrete(materialClasses.concrete);
  }, [materialClasses]);

  useEffect(() => {
    log(cls, "Data from API: ", singleDimensioningDataFromApi, showLogs);

    setM_rd(singleDimensioningDataFromApi.m_rd);
    setKsi_eff(singleDimensioningDataFromApi.ksi_eff);
    setX_eff(singleDimensioningDataFromApi.x_eff);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDimensioningDataFromApi]);

  useEffect(() => {
    setSingleDrawData({
      b: b,
      h: h,
      c: c,
      fi: fi,
      fi_s: fi_s,
      x_eff: x_eff,
      no_of_bars: no_of_bars,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b, h, c, fi, fi_s, x_eff, no_of_bars]);

  const sendDataToApi = (event) => {
    const dataToSend = {
      name,
      b,
      h,
      cl_conc,
      cl_steel,
      c,
      fi,
      no_of_bars,
      fi_s,
    };
    log(cls, "Sending data for API: ", dataToSend, showLogs);

    setupDataModel(true);
    setSingleDimensioningData(dataToSend);
    event.preventDefault();
  };

  /**
   *
   * @param {*} props
   */
  const setupDataModel = (props) => {
    const isButtonPressed = props;
    const isNoErrors = apiTrigger.isNoErrors;
    const isWaitForAction = apiTrigger.isWaitForAction;

    const dataModel = { isButtonPressed, isNoErrors, isWaitForAction };

    setApiTrigger(dataModel);
  };

  return (
    <>
      <MaterialsApi />
      <SingleReinfConcreteApi />
      <SingleReinfConcreteErrHandler />
      <NavigationBar />
      <Container fluid>
        <Row>
          <Col xxl={1}></Col>
          <Col>
            <h3>{pageTitles.SINGLE_CALC_TITLE}</h3>
            <Row>
              <Col xxl={5} xl={5} lg={4}>
                <Form>
                  <FormGroupNameInput setName={setName}></FormGroupNameInput>
                  <Row>
                    <FormBvalueInput b={b} setB={setB}></FormBvalueInput>
                  </Row>
                  <Row>
                    <FormHvalueInput h={h} setH={setH}></FormHvalueInput>
                  </Row>

                  <Row>
                    <Col>
                      <FormClConcValueInput
                        setCl_conc={setCl_conc}
                        concrete={concrete}
                      ></FormClConcValueInput>
                    </Col>
                    <Col>
                      <FormClSteelValueInput
                        setCl_steel={setCl_steel}
                        steel={steel}
                      ></FormClSteelValueInput>
                    </Col>
                  </Row>
                  <Row>
                    <FormCvalueInput c={c} setC={setC}></FormCvalueInput>
                  </Row>
                  <FormFiSValueInput setFi_s={setFi_s}></FormFiSValueInput>
                  <Row>
                    <FormNoOfRebarsValueInput
                      no_of_bars={no_of_bars}
                      setNo_of_bars={setNo_of_bars}
                      title={"Number of main rebars : "}
                      placeholder={8}
                    ></FormNoOfRebarsValueInput>
                  </Row>
                  <FormFiValueInput setFi={setFi}></FormFiValueInput>
                </Form>
              </Col>
              <Col>
                <JSXdrawSingle />
                <br />
                <Container fluid="md">
                  <MDBBtn
                    color="dark"
                    type="button"
                    className="btn btn-primary"
                    aria-controls="example-collapse-text"
                    aria-expanded={true}
                    onClick={(event) => {
                      sendDataToApi(event);
                    }}
                  >
                    Calculate
                  </MDBBtn>
                </Container>
              </Col>
            </Row>
            <br></br>
            <SingleReinfConcreteResultView
              isCollapseOpen={true}
              m_rd={m_rd}
              ksi_eff={ksi_eff}
              x_eff={x_eff}
            />
          </Col>
          <Col xxl={1}></Col>
        </Row>
      </Container>
    </>
  );
};

export default SingleReinfConcreteCalcView;
