import { log, jsClasses, getZoomFactorForLines } from "../../../utils/Utils";

/** Show log in console if true */
const showLogs = true;
/** Class name for log */
const cls = jsClasses.TeeDimensionLines;

const TeeDimensionLines = () => {};

/**
 * Rysuje linie wymiarowe dla segmentu RECTANGLE w kalkulatorze kompleksowym
 * @param {*} board Referencja tablicy
 * @param {*} points Tablic punktów obrysu przekroju
 */
export function drawTeeDimensionLines(board, points) {
  log(cls, "Points", { points }, showLogs);
  drawLine_B(board, points);
  drawLine_B_eff(board, points);
  drawLine_H(board, points);
  drawLine_H_sl(board, points);
}

function drawLine_H_sl(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return (
          points.current[1].X() +
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[1].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  var p1 = board.create(
    "point",
    [
      function () {
        return (
          points.current[1].X() +
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[2].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[1], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[2], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje boczną linię wymiarową dla wartości b
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_B(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return points.current[4].X();
      },

      function () {
        return (
          points.current[4].Y() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p1 = board.create(
    "point",
    [
      function () {
        return points.current[5].X();
      },

      function () {
        return (
          points.current[5].Y() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[4], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[5], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje boczną linię wymiarową dla wartości h
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_H(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return (
          points.current[7].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[7].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p1 = board.create(
    "point",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[5].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[7], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[5], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje górną linię wymiarową dla wartości beff
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju RECTANGLE
 */
function drawLine_B_eff(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return points.current[0].X();
      },

      function () {
        return (
          points.current[0].Y() +
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  var p1 = board.create(
    "point",
    [
      function () {
        return points.current[1].X();
      },

      function () {
        return (
          points.current[1].Y() +
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[0], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[1], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

export default TeeDimensionLines;
