import { log, jsClasses, getZoomFactorForLines } from "../../../utils/Utils";

/** Show log in console if true */
const showLogs = true;
/** Class name for log */
const cls = jsClasses.DoubleTeeDimensionLines;

const DoubleTeeDimensionLines = () => {};

/**
 * Rysuje linie wymiarowe dla segmentu RECTANGLE w kalkulatorze kompleksowym
 * @param {*} board Referencja tablicy
 * @param {*} points Tablic punktów obrysu przekroju
 */
export function drawDoubleTeeDimensionLines(board, points) {
  log(cls, "Points", { points }, showLogs);
  drawLine_B1(board, points);
  drawLine_B2(board, points);
  drawLine_B3(board, points);
  drawLine_H1(board, points);
  drawLine_H2(board, points);
  drawLine_H3(board, points);
}

/**
 * Rysuje linię wymiarową dla wartości h3
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_H3(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[11].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p1 = board.create(
    "point",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[0].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[11], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[0], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje linię wymiarową dla wartości h2
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_H2(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[8].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p1 = board.create(
    "point",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[11].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[8], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[11], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje linię wymiarową dla wartości h1
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_H1(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[8].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p1 = board.create(
    "point",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },

      function () {
        return points.current[7].Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[8], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[7], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje linię wymiarową dla wartości b1
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_B1(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return points.current[7].X();
      },

      function () {
        return (
          points.current[7].Y() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p1 = board.create(
    "point",
    [
      function () {
        return points.current[6].X();
      },

      function () {
        return (
          points.current[6].Y() -
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[7], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[6], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje linię wymiarową dla wartości b2
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_B2(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return points.current[10].X();
      },

      function () {
        return (
          points.current[10].Y() -
          (points.current[10].Y() - points.current[9].Y()) / 2
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  var p1 = board.create(
    "point",
    [
      function () {
        return points.current[3].X();
      },

      function () {
        return (
          points.current[3].Y() -
          (points.current[3].Y() - points.current[4].Y()) / 2
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });
}

/**
 * Rysuje linię wymiarową dla wartości b3
 * @param {*} board Referencja tablicy
 * @param {*} points Tablica punktów obrysu przekroju
 */
function drawLine_B3(board, points) {
  var p0 = board.create(
    "point",
    [
      function () {
        return points.current[0].X();
      },

      function () {
        return (
          points.current[0].Y() +
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  var p1 = board.create(
    "point",
    [
      function () {
        return points.current[1].X();
      },

      function () {
        return (
          points.current[1].Y() +
          getZoomFactorForLines(points.current[0].Y() + points.current[1].X())
        );
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  board.create("segment", [p0, p1], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [points.current[0], p0], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [points.current[1], p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

export default DoubleTeeDimensionLines;
