var tendons = []; // Zawiera cięgna (obiekty Circle bliblioteki JSXGraph)
var insidePoints = []; // Zawiera punkty środka dla obiektu Circle
var outsidePoints = []; // Zawiera punkty krańcowe obiektu Circle
var circlePointsArrays = []; // Przechowuje tablicę tablic zawierających punkty insidePoint & outsidePoint obiektu Circle

const TENDON_FI = 0.09; // Średnica cięgna (metry)
const POINT_ATTRIBUTES = {
  visible: false,
  withLabel: false,
  size: 1,
};

const BoxTendons = () => {};

export function drawTendons(board, props, points) {
  board.suspendUpdate();
  board.removeObject(tendons);
  board.removeObject(insidePoints);
  board.removeObject(outsidePoints);
  tendons = [];
  insidePoints = [];
  outsidePoints = [];
  circlePointsArrays = [];
  draw(board, props, points);
  board.unsuspendUpdate();
}

function draw(board, props, points) {
  let tendonsArray = props.tendon_info;
  let tendonsRowCount = tendonsArray[0];
  let distanceBtwTendons = []; // Przechowuje odległości między cięgnami, element tablicy odpowiada numerowi rzędu cięgien - el[3] tj. rząd cięgien nr 3

  for (let i = 1; i < tendonsRowCount + 1; i++) {
    let tendonsCount = tendonsArray[i][1];
    let yDistance = tendonsArray[i][0]; // Odległość cięgna od spodu przekroju

    var distance = points[4].X() - TENDON_FI - (points[5].X() + TENDON_FI);
    distanceBtwTendons[i] = distance / (tendonsCount + 1);

    for (let j = 0; j < tendonsCount; j++) {
      var insidePoint = board.create(
        "point",
        [
          function () {
            return points[5].X() + TENDON_FI + (j + 1) * distanceBtwTendons[i];
          },
          function () {
            return points[5].Y() + yDistance;
          },
        ],
        POINT_ATTRIBUTES
      );

      var outsidePoint = board.create(
        "point",
        [
          function () {
            return points[5].X() + TENDON_FI + (j + 1) * distanceBtwTendons[i];
          },
          function () {
            return points[5].Y() + yDistance + TENDON_FI;
          },
        ],
        POINT_ATTRIBUTES
      );

      var arr = [insidePoint, outsidePoint];
      circlePointsArrays.push(arr);

      insidePoints.push(insidePoint);
      outsidePoints.push(outsidePoint);
    }
  }

  for (let x = 0; x < circlePointsArrays.length; x++) {
    var tendonCircle = board.create("circle", circlePointsArrays[x], {
      strokeColor: "blue",
      fillColor: "blue",
    });

    tendons.push(tendonCircle);
  }
}

export default BoxTendons;
