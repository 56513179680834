import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import JSXBoard from "../../../JSXBoard";
import { log, jsClasses, getBoudingBoxArray } from "../../../utils/Utils";
import { useGfxContext } from "../../../context/GfxContext";
import { Table } from "react-bootstrap";

const StressInConcrateDraw = () => {
  /** Show log in console if true */
  const showLogs = false;
  /** Class name for log */
  const cls = jsClasses.StressInConcrateDraw;

  const boardRef = useRef();
  const stress_in_concrete = useRef([]);
  const draw_concrete_stress_lower_limit = useRef();
  const draw_concrete_stress_upper_limit = useRef();
  const concrete_stress_lower_limit = useRef();
  const concrete_stress_upper_limit = useRef();
  const boardBox = useRef([-1, 2, 5, -5]); // [Xdown, Yup, Xup, Ydown]
  const chart = useRef();
  const polygon = useRef();

  const { complexResultDrawData } = useGfxContext();

  useEffect(() => {
    log(cls, "Otrzymano dane od API", complexResultDrawData, showLogs);

    if (complexResultDrawData.stress_in_concrete !== undefined) {
      boardRef.current.suspendUpdate();
      // eslint-disable-next-line array-callback-return
      stress_in_concrete.current = complexResultDrawData.stress_in_concrete;
      draw_concrete_stress_lower_limit.current =
        complexResultDrawData.draw_concrete_stress_lower_limit;
      draw_concrete_stress_upper_limit.current =
        complexResultDrawData.draw_concrete_stress_upper_limit;
      concrete_stress_lower_limit.current =
        complexResultDrawData.concrete_stress_lower_limit;
      concrete_stress_upper_limit.current =
        complexResultDrawData.concrete_stress_upper_limit;
      boardRef.current.removeObject(chart.current, false);
      boardRef.current.removeObject(polygon.current, false);
      createDraw();
      boardRef.current.unsuspendUpdate();
    }

    if (complexResultDrawData.stress_in_concrete !== undefined) {
      updateBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complexResultDrawData]);

  const gfx = (board) => {
    boardRef.current = board;
  };

  const updateBoard = () => {
    if (boardRef.current !== undefined) {
      boardRef.current.fullUpdate();
    }
  };

  const createDraw = () => {
    boardBox.current = getBoudingBoxArray(stress_in_concrete.current);

    boardRef.current.setBoundingBox(boardBox.current, false, "reset");
    chart.current = boardRef.current.create(
      "chart",
      stress_in_concrete.current,
      {
        chartStyle: "bar",
        colors: ["#1E90FF"],
        width: 0.01,
        fillOpacity: 0.5,
        dir: "horizontal",
      }
    );
    if (
      Boolean(draw_concrete_stress_lower_limit.current) !== false &&
      typeof draw_concrete_stress_lower_limit.current === "number"
    ) {
      polygon.current = boardRef.current.create(
        "polygon",
        [
          [draw_concrete_stress_lower_limit.current, 20],
          [draw_concrete_stress_lower_limit.current, -20],
        ],
        {
          borders: { strokeWidth: 0.7, strokeColor: "red" },
          fillOpacity: 0.5,
          fillColor: "#cdfee0",
        }
      );
    }
    if (
      Boolean(draw_concrete_stress_upper_limit.current) !== false &&
      typeof draw_concrete_stress_upper_limit.current === "number"
    ) {
      polygon.current = boardRef.current.create(
        "polygon",
        [
          [draw_concrete_stress_upper_limit.current, 20],
          [draw_concrete_stress_upper_limit.current, -20],
        ],
        {
          borders: { strokeWidth: 0.7, strokeColor: "red" },
          fillOpacity: 0.5,
          fillColor: "#cdfee0",
        }
      );
    }
  };

  return (
    <Container>
      <JSXBoard
        logic={gfx}
        boardAttributes={{
          axis: true,
          boundingbox: [-0.1, 2, 2, -0.25],
          showCopyright: false,
        }}
        style={{
          border: "1px solid black",
        }}
      />
      <Table striped bordered hover>
        <tr>
          <td className="text-danger">
            {typeof draw_concrete_stress_lower_limit.current === "number" && (
              <h6>{concrete_stress_lower_limit.current}</h6>
            )}
          </td>
          <td className="text-danger">
            {typeof draw_concrete_stress_upper_limit.current === "number" && (
              <h6>{concrete_stress_upper_limit.current}</h6>
            )}
          </td>
        </tr>
      </Table>
    </Container>
  );
};

export default StressInConcrateDraw;
