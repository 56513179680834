/**
 *  Forma umożliwiająca wpisanie 3 wartości dla wymiaru 'b'
 */

/* eslint-disable eqeqeq */
import React from "react";
import { useRef, useEffect } from "react";
import { Row, Form } from "react-bootstrap";

import { log, jsClasses } from "../../utils/Utils";

/** Show log in console if true, class name for log */
const showLogs = true;
const cls = jsClasses.FormNoOfOppBarsInput;

const FormNoOfOppBarsInput = (props) => {

  const no_of_bars = useRef(1);
  const valueA = useRef(20);
  const valueB = useRef(0);
  const valueC = useRef(0);

  const setupValueA = (nob, value) => {
    no_of_bars.current = nob;
    valueA.current = value;
    props.setNl_reinf_top([
      nob,
      [valueA.current, valueB.current, valueC.current],
    ]);
  };

  const setupValueB = (nob, value) => {
    no_of_bars.current = nob;
    valueB.current = value;
    props.setNl_reinf_top([
      nob,
      [valueA.current, valueB.current, valueC.current],
    ]);
  };

  const setupValueC = (nob, value) => {
    no_of_bars.current = nob;
    valueC.current = value;
    props.setNl_reinf_top([
      nob,
      [valueA.current, valueB.current, valueC.current],
    ]);
  };

  useEffect(() => {
    log(cls, "number of rows is change", props, showLogs);
    if (props.numberOfRows == 2) {
      log(cls, "main rows = 2", props.numberOfRows, showLogs);
      setupValueC(2, 0);
    } else if (props.numberOfRows == 1) {
      log(cls, "main rows = 1", props.numberOfRows, showLogs);
      setupValueC(1, 0);
      setupValueB(1, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.numberOfRows]);

  return (
    <>
      {props.numberOfRows == 3 && (
        <>
          <Row>
            <h6>Number of main rebars in 1st row:</h6>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueA(3, parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Control
                id="input1"
                type="number"
                placeholder={valueA.current}
              />
            </Form.Group>
          </Row>
          <Row>
            <h6>Number of main rebars in 2nd row:</h6>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueB(3, parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Control
                id="input2"
                type="number"
                placeholder={valueB.current}
              />
            </Form.Group>
          </Row>
          <Row>
            <h6>Number of main rebars in 3rd row:</h6>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueC(3, parseFloat(e.target.value.replace(",", ".")));
              }}
            >
              <Form.Control
                id="input3"
                type="number"
                placeholder={valueC.current}
              />
            </Form.Group>
          </Row>
        </>
      )}
      {props.numberOfRows == 2 && (
        <>
          <Row>
            <h6>Number of main rebars in 1st row:</h6>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueA(2, parseFloat(e.target.value.replace(",", ".")));
                setupValueC(2, 0);
              }}
            >
              <Form.Control
                id="input1"
                type="number"
                placeholder={valueA.current}
              />
            </Form.Group>
          </Row>
          <Row>
            <h6>Number of main rebars in 2nd row:</h6>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueB(2, parseFloat(e.target.value.replace(",", ".")));
                setupValueC(2, 0);
              }}
            >
              <Form.Control
                id="input2"
                type="number"
                placeholder={valueB.current}
              />
            </Form.Group>
          </Row>
        </>
      )}
      {props.numberOfRows == 1 && (
        <>
          <Row>
            <h6>Number of main rebars in 1st row:</h6>
            <Form.Group
              className="mb-3"
              onChange={(e) => {
                setupValueA(1, parseFloat(e.target.value.replace(",", ".")));
                setupValueB(1, 0);
                setupValueC(1, 0);
              }}
            >
              <Form.Control
                id="input1"
                type="number"
                placeholder={valueA.current}
              />
            </Form.Group>
          </Row>
        </>
      )}
    </>
  );
};

export default FormNoOfOppBarsInput;
