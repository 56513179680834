import { calcTypes, circleComponentPointType } from "../utils/StringsConst";

/**
 * Klasa wspólna zawierająca funkcje do rysowania cięgien
 */

const TendonsDrawCommon = () => {};

/**
 * Zwraca punkt zewnętrzny lub wewnętrzny w zależności od zadanego typu dla komponentu CIRCLE JSXGraph
 * @param {*} board: Komponent tablicy JSXGraph do rysowania
 * @param {*} distanceBtwTendons: Tablica zawierająca odległości między cięgnami
 * @param {*} points: Tablica punktów przekroju
 * @param {*} yOffset: Offset, punkt początkowy osi Y informujący od jakiego miejsca rozpocząć proces rysowania
 * @param {*} tendonRow: Aktualny rząd cięgien dla którego ma zostać wygenerowany punkt
 * @param {*} tendonNo: Aktualne cięgno w rzędzie dla którego ma zostać wygenerowany punkt
 * @param {*} typeOfCirclePoint: Typ punktu do wygenerowania -> zewnętrzny (OUTSIDE_POINT) lub wewnętrzny (INSIDE_POINT)
 * @param {*} tendonDiameter: Średnica cięgna (metry)
 * @param {*} tendonRadius: Promień cięgna (metry)
 * @param {*} sectionType: Typ sekcji kompleksowego kalkulatora
 * @returns Komponent POINT JSXGraph
 */
export function getCirclePoint(
  board,
  distanceBtwTendons,
  points,
  yOffset,
  tendonRow,
  tendonNo,
  typeOfCirclePoint,
  tendonDiameter,
  tendonRadius,
  sectionType
) {
  const POINT_ATTRIBUTES = {
    visible: false,
    withLabel: false,
    size: 1,
  };

  if (sectionType === calcTypes.DOUBLE_TEE_TYPE_CALC)
    return board.create(
      "point",
      [
        function () {
          if (points[7].Y() + yOffset > points[11].Y()) {
            return (
              points[0].X() +
              tendonDiameter +
              (tendonNo + 1) * distanceBtwTendons[tendonRow]
            );
          } else if (points[7].Y() + yOffset > points[8].Y()) {
            return (
              points[9].X() +
              tendonDiameter +
              (tendonNo + 1) * distanceBtwTendons[tendonRow]
            );
          }
          return (
            points[7].X() +
            tendonDiameter +
            (tendonNo + 1) * distanceBtwTendons[tendonRow]
          );
        },
        function () {
          if (typeOfCirclePoint === circleComponentPointType.OUTSIDE_POINT) {
            return points[7].Y() + yOffset + tendonRadius;
          } else {
            return points[7].Y() + yOffset;
          }
        },
      ],
      POINT_ATTRIBUTES
    );

  if (sectionType === calcTypes.RECTANGLE_TYPE_CALC) {
    return board.create(
      "point",
      [
        function () {
          return (
            points[3].X() +
            tendonDiameter +
            (tendonNo + 1) * distanceBtwTendons[tendonRow]
          );
        },
        function () {
          if (typeOfCirclePoint === circleComponentPointType.OUTSIDE_POINT) {
            return points[3].Y() + yOffset + tendonRadius;
          } else {
            return points[3].Y() + yOffset;
          }
        },
      ],
      POINT_ATTRIBUTES
    );
  }

  if (sectionType === calcTypes.TEE_TYPE_CALC) {
    return board.create(
      "point",
      [
        function () {
          if (points[5].Y() + yOffset > points[7].Y()) {
            return (
              points[0].X() +
              tendonDiameter +
              (tendonNo + 1) * distanceBtwTendons[tendonRow]
            );
          } else {
            return (
              points[5].X() +
              tendonDiameter +
              (tendonNo + 1) * distanceBtwTendons[tendonRow]
            );
          }
        },
        function () {
          if (typeOfCirclePoint === circleComponentPointType.OUTSIDE_POINT) {
            return points[5].Y() + yOffset + tendonRadius;
          } else {
            return points[5].Y() + yOffset;
          }
        },
      ],
      POINT_ATTRIBUTES
    );
  }
}

/**
 * Ustala odległośc między cięgnami w zadanym rzędzie cięgien.
 * @param {*} points: Tablica zawierająca punkty przekroju
 * @param {*} tendonsCount: Ilość punktów przekroju
 * @param {*} distanceBtwTendons: Zmienna przechowująca tablice z odległościami między cięgnami
 * @param {*} tendonsRow: Aktualny rząd cięgien którego dotyczą obliczenia
 * @param {*} rightPoint: Prawy punkt przekroju do obliczenia odległości do dyspozycji dla rzędu cięgien
 * @param {*} leftPoint:  Lewy punkt przekroju do obliczenia odległości do dyspozycji dla rzędu cięgien
 * @param {*} tendonDiameter: Średnica cięgna
 */
export function setupTendonsDistances(
  points,
  tendonsCount,
  distanceBtwTendons,
  tendonsRow,
  rightPoint,
  leftPoint,
  tendonDiameter
) {
  var distance = 0; // Dystans, odległość do dyspozycji do rysowania cięgnien. Długość b1, b2, b3 pomniejszona o minimalną odległość cięgna od ścianki przekroju.
  var bDistance = points[rightPoint].X() - points[leftPoint].X(); // Zmienna przechowuje wymiar dystansu b1, b2 lub b3
  var sumOfTendonsDiameters = tendonsCount * tendonDiameter; // Suma średnic cięgien w jednym rzędzie
  var sumOfMinDistancesBetweenTendons = tendonDiameter * (tendonsCount + 1); // Suma minimalnej odległości cięgien między sobą oraz odległości od ścian

  /* Sprawdzenie czy suma średnic cięgien oraz suma odległości między cięgnami nie przekracza wymiaru b1, b2 lub b3 */
  if (sumOfTendonsDiameters + sumOfMinDistancesBetweenTendons >= bDistance) {
    distance = sumOfTendonsDiameters + sumOfMinDistancesBetweenTendons;
    distanceBtwTendons[tendonsRow] = distance / (tendonsCount + 1);
  } else {
    distance =
      points[rightPoint].X() -
      tendonDiameter -
      (points[leftPoint].X() + tendonDiameter);
    distanceBtwTendons[tendonsRow] = distance / (tendonsCount + 1);
  }
}

export default TendonsDrawCommon;
