import React from "react";
import { Container, Accordion } from "react-bootstrap";

const ComplexAssumptions = () => {
  return (
    <Container>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Description of the calculator's assumptions
          </Accordion.Header>
          <Accordion.Body>
            <article>
              <p>
                This calculator iteratively solves systems of nonlinear
                equations in order to use such stress and strain distributions
                in individual elements so that their integral returns the forces
                given by the user. Contrary to alternative solutions, the system
                is not limited to the calculation of the capacity envelope. On
                the contrary, the user obtains full information on the stress
                and strains distributions in all the elements and all layers of
                the cross-section.
              </p>
              <h5>Calculator assumptions:</h5>
              <ol>
                <li>
                  Stress distribution in concrete - parabolic (Madrid parabola)
                </li>
                <li>
                  Stress strain relation in steel - elastic with a quasi plastic
                  flat region.
                </li>
                <li>
                  The calculator is dedicated to orthogonal, reinforced concrete
                  and post-tensioned sections (initial version for testing).
                </li>
              </ol>
              <h5>Class defaults:</h5>
              <ul>
                <li>
                  EC2 = 0.0020 # concrete strain of section in the beginning of
                  flat (plastic) stresses{" "}
                </li>
                <li>ECU2 = 0.0035 # max. concrete strain of section</li>
                <li>
                  EYD = 0.00217 # steel strain in Hook's region (FOR BOTH B500SP
                  AND BST500S STEEL)
                </li>

                <li>
                  E_STELL = 200 * 10 ** 3 # modulus from elasticity of r. Steel
                </li>
                <li>
                  E_TENDONS = 195 * 10 ** 3 # modulus from elasticity of
                  prestressing strands
                </li>
                <li>
                  CONC_L_THIC = 0.005 [m] # concrete layer thickness of virtual
                  division of the concrete cross-section for the needs of
                  numerical integrals
                </li>
              </ul>
            </article>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br></br>
    </Container>
  );
};

export default ComplexAssumptions;
