import { /*log, jsClasses,*/ getZoomFactorForLines } from "../../utils/Utils";

/** Show log in console if true */
//const showLogs = true;
/** Class name for log */
//const cls = jsClasses.SingleDrawDimensionLines;

const SingleDrawDimensionLines = () => {};

/**
 * Draw dimension lines on board
 * @param {Board for drawing} board
 */
export function drawDimensionLines(board, c, pA, pB, pC, pD) {
  draw_H_DimensionLine(board, pA, pB, pD);
  draw_B_DimensionLine(board, pA, pB);
  draw_C_DimensionLine(board, c, pA, pB, pC);
}

/**
 * Rysuje linie wymiarową C na rysunku
 * @param {Board} board Tablica JSXGrpah
 * @param {*} pA Punk A
 * @param {*} pB Punk B
 */
function draw_C_DimensionLine(board, c, pA, pB, pC) {
  var p1 = board.create(
    "point",
    [
      function () {
        return pC.X() + getZoomFactorForLines(pA.Y() + pB.X());
      },

      function () {
        return 0;
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p2 = board.create(
    "point",
    [
      function () {
        return pC.X() + getZoomFactorForLines(pA.Y() + pB.X());
      },

      function () {
        return pC.Y() + c.current / 1000;
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  var p3 = board.create(
    "point",
    [
      function () {
        return pC.X();
      },

      function () {
        return pC.Y() + c.current / 1000;
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  board.create("segment", [p1, p2], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [pC, p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [p2, p3], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje linie wymiarową B na rysunku
 * @param {Board} board Tablica JSXGrpah
 * @param {*} pA Punk A
 * @param {*} pB Punk B
 */
function draw_B_DimensionLine(board, pA, pB) {
  var p1 = board.create(
    "point",
    [
      function () {
        return pA.X();
      },

      function () {
        return pA.Y() + getZoomFactorForLines(pA.Y() + pB.X());
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  var p2 = board.create(
    "point",
    [
      function () {
        return pB.X();
      },

      function () {
        return pB.Y() + getZoomFactorForLines(pA.Y() + pB.X());
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );
  board.create("segment", [p1, p2], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [pA, p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [pB, p2], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

/**
 * Rysuje linie wymiarową H na rysunku
 * @param {Board} board Tablica JSXGrpah
 * @param {*} pA Punk A
 * @param {*} pD Punk D
 */
function draw_H_DimensionLine(board, pA, pB, pD) {
  var p1 = board.create(
    "point",
    [
      function () {
        return pA.X() - getZoomFactorForLines(pA.Y() + pB.X());
      },

      function () {
        return pA.Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  var p2 = board.create(
    "point",
    [
      function () {
        return pA.X() - getZoomFactorForLines(pA.Y() + pB.X());
      },

      function () {
        return pD.Y();
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: 0,
    }
  );

  board.create("segment", [p1, p2], {
    name: "seg",
    strokeColor: "#000000",
    strokeWidth: 2,
    highlightStrokeWidth: 5,
    lastArrow: { type: 2, size: 8, highlightSize: 6 },
    touchLastPoint: true,
    firstArrow: { type: 2, size: 8, highlightSize: 6 },
  });

  board.create("segment", [pA, p1], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });

  board.create("segment", [pD, p2], {
    strokeColor: "#000000",
    strokeWidth: 1,
    highlightStrokeWidth: 5,
    dash: 2,
  });
}

export default SingleDrawDimensionLines;
