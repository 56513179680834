import React from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { pageTitles } from "./utils/StringsConst";

const HomeNavBar = () => {
  // eslint-disable-next-line no-unused-vars

  return (
    <div className="d-flex" style={{ width: "75vh" }}>
      <MDBDropdown>
        <MDBDropdownToggle color="dark">choose module</MDBDropdownToggle>
        <MDBDropdownMenu dark>
          <MDBDropdownItem
            transparent
            link
            href="/calcs/reinforcedconcretecalc"
          >
            {pageTitles.SINGLE_CALC_TITLE}
          </MDBDropdownItem>
          <MDBDropdownItem link href="/calcs/doublerectcalc">
            {pageTitles.DOUBLE_CALC_TITLE}
          </MDBDropdownItem>
          <MDBDropdownItem link href="/calcs/rectfindreinfcalc">
            {pageTitles.SINGLE_DIMENSIONING_CALC_TITLE}
          </MDBDropdownItem>
          <MDBDropdownItem link href="/calcs/tsecreinfcalc">
            {pageTitles.TEE_CALC_TITLE}
          </MDBDropdownItem>
          <MDBDropdownItem link href="/calcs/ComplexCalc">
            {pageTitles.COMPLEX_CALC_TITLE}
          </MDBDropdownItem>
          {/* <MDBDropdownItem link href="/calcs/CreepShrinkCalc">
            {pageTitles.CREEP_SHRINK}
          </MDBDropdownItem> */}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );

  // return (
  //   <>
  //     <Container>
  //       <Nav variant="pills" className="me-auto" defaultActiveKey="/">
  //         <NavDropdown title="Choose module of interest" id="nav-dropdown">
  //           <NavDropdown.Item
  //             eventKey="4.2"
  //             href="/calcs/reinforcedconcretecalc"
  //           >
  //             {pageTitles.SINGLE_CALC_TITLE}
  //           </NavDropdown.Item>
  //           <NavDropdown.Item eventKey="4.3" href="/calcs/doublerectcalc">
  //             {pageTitles.DOUBLE_CALC_TITLE}
  //           </NavDropdown.Item>
  //           <NavDropdown.Item eventKey="4.4" href="/calcs/rectfindreinfcalc">
  //             {pageTitles.SINGLE_DIMENSIONING_CALC_TITLE}
  //           </NavDropdown.Item>
  //           <NavDropdown.Item eventKey="4.5" href="/calcs/tsecreinfcalc">
  //             {pageTitles.TEE_CALC_TITLE}
  //           </NavDropdown.Item>
  //           <NavDropdown.Item eventKey="4.6" href="/calcs/ComplexCalc">
  //             {pageTitles.COMPLEX_CALC_TITLE}
  //           </NavDropdown.Item>
  //           <NavDropdown.Item eventKey="4.7" href="/calcs/CreepShrinkCalc">
  //             {pageTitles.creepShrinkCalcTitle}
  //           </NavDropdown.Item>
  //         </NavDropdown>
  //       </Nav>
  //       <Navbar.Toggle />
  //     </Container>
  //     <br />
  //   </>
  // );
};

export default HomeNavBar;
