import { MDBContainer } from "mdb-react-ui-kit";
import { useSyncExternalStore } from "react";
import HomeNavBar from "../HomeNavBar";
import LoginComponent from "./homeComponents/LoginComponent";
import TitleComponent from "./homeComponents/TitleComponent";
import FunctionsDescComponent from "./homeComponents/FunctionsDescComponent";
import React from "react";

const store = {
  size: {
    height: undefined,
    width: undefined,
    ratio: undefined,
  },
};

const HomeView = () => {
  // eslint-disable-next-line no-unused-vars
  const { width, height, ratio } = useSyncExternalStore(subscribe, getSnapshot);

  function getSnapshot() {
    if (
      store.size.height !== window.innerHeight ||
      store.size.width !== window.innerWidth
    ) {
      store.size = {
        height: window.innerHeight,
        width: window.innerWidth,
        ratio: window.innerWidth / window.innerHeight,
      };
    }
    return store.size;
  }

  function subscribe(callback) {
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }

  return (
    <div
      className="bg-image"
      style={{
        backgroundImage: "url('lpb.jpg')",
        height: "100vh",
      }}
    >
      <div
        className="mask"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.00)" }}
      >
        <MDBContainer fluid>
          <LoginComponent />

          <div className="d-flex">
            {ratio > 2 ? (
              <div
                className="d-flex"
                style={{ width: 75 + (ratio - 2) * 75 + "vh" }}
              />
            ) : (
              <div className="d-flex" style={{ width: "75vh" }} />
            )}
            <TitleComponent />
          </div>

          <div className="d-flex" style={{ height: "4vh" }} />

          <div className="d-flex">
            {ratio > 2 ? (
              <div
                className="d-flex"
                style={{ width: 85 + (ratio - 2) * 75 + "vh" }}
              />
            ) : (
              <div className="d-flex" style={{ width: "85vh" }} />
            )}
            <HomeNavBar />
          </div>
          {/* <div className="text-dark">{ratio}</div> */}

          <div className="d-flex" style={{ height: "35vh" }} />
          <div className="d-flex justify-content-end">
            <FunctionsDescComponent />
            <div className="d-flex" style={{ width: "4vh" }} />
          </div>
        </MDBContainer>
      </div>
    </div>
  );
};

export default HomeView;
