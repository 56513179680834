import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col } from "react-bootstrap";
import JSXdrawT from "../../graphics/daynamicDrawT/JSXdrawT";

import NavigationBar from "../../NavigationBar";
import TsecReinfErrHandler from "./TsecReinfErrHandler";
import TsecReinfResultView from "./TsecReinfResultView";
import TsecReinfApi from "./TsecReinfApi";
import MaterialsApi from "../../utils/MaterialsApi";

import { log, jsClasses } from "./../../utils/Utils";
import { pageTitles } from "../../utils/StringsConst";

import {
  FormGroupNameInput,
  FormBvalueInput,
  FormHvalueInput,
  FormCvalueInput,
  FormMsdValueImput,
  FormFiSValueInput,
  FormFiValueInput,
  FormFiOppValueInput,
  FormClConcValueInput,
  FormClSteelValueInput,
} from "../commons/CommonFormGroups";

import { useGlobalContext } from "./../../Context";
import { useGfxContext } from "../../context/GfxContext";
import { useApiMaterialContext } from "../../context/ApiMaterialContext";
import { MDBBtn } from "mdb-react-ui-kit";

/** Show log in console if true */
const showLogs = false;
/** Class name for log */
const cls = jsClasses.TsecReinfCalcView;

const TsecReinfCalcView = () => {
  const [name, setName] = useState("My first cross sect");
  const [b, setB] = useState(0.5);
  const [h, setH] = useState(1.4);
  const [h_sl, setH_sl] = useState(0.2);
  const [b_eff, setB_eff] = useState(1);
  const [cl_conc, setCl_conc] = useState("C30_37");
  const [cl_steel, setCl_steel] = useState("BSt500S");
  const [c, setC] = useState(30);
  const [fi, setFi] = useState(32);
  const [fi_s, setFi_s] = useState(12);
  const [fi_opp, setFi_opp] = useState(32);
  const [m_sd, setM_sd] = useState(3000);
  /* JSON Api data */
  const [as1, setAs1] = useState(0);
  const [ns1, setNs1] = useState(0);
  const [as2, setAs2] = useState(0);
  const [ns2, setNs2] = useState(0);
  const [remark, setRemark] = useState("");
  const [remark2, setRemark2] = useState("");
  /* Material classes */
  const [steel, setSteel] = useState([]);
  const [concrete, setConcrete] = useState([]);

  const {
    setTreinforcedConcreteData,
    apiTrigger,
    setApiTrigger,
    tReinforcedConcreteDataFromApi,
  } = useGlobalContext();

  const { setTDrawData } = useGfxContext();

  const { materialClasses } = useApiMaterialContext();

  useEffect(() => {
    log(cls, "Data from Material API: ", materialClasses, showLogs);
    setSteel(materialClasses.steel);
    setConcrete(materialClasses.concrete);
  }, [materialClasses]);

  useEffect(() => {
    setTDrawData({
      b: b,
      c: c,
      b_eff: b_eff,
      h: h,
      h_sl: h_sl,
      fi: fi,
      fi_opp: fi_opp,
      fi_s: fi_s,
      ns1: ns1,
      ns2: ns2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b, c, h, h_sl, b_eff, fi, fi_opp, fi_s, ns1, ns2]);

  useEffect(() => {
    log(
      cls,
      "Reciving data from API",
      tReinforcedConcreteDataFromApi,
      showLogs
    );

    setAs1(tReinforcedConcreteDataFromApi.as1);
    setNs1(tReinforcedConcreteDataFromApi.ns1);
    setAs2(tReinforcedConcreteDataFromApi.as2);
    setNs2(tReinforcedConcreteDataFromApi.ns2);
    setRemark(tReinforcedConcreteDataFromApi.remark);
    setRemark2(tReinforcedConcreteDataFromApi.remark2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tReinforcedConcreteDataFromApi]);

  useEffect(() => {
    log(cls, "Data from Material API: ", materialClasses, showLogs);
    setSteel(materialClasses.steel);
    setConcrete(materialClasses.concrete);
  }, [materialClasses]);

  const sendDataToApi = (event) => {
    event.preventDefault();
    const dataToSend = {
      name,
      b,
      h,
      h_sl,
      b_eff,
      cl_conc,
      cl_steel,
      c,
      fi,
      fi_s,
      fi_opp,
      m_sd,
    };
    log(cls, "sendDataToApi", dataToSend, showLogs);

    setupDataModel(true);
    setTreinforcedConcreteData(dataToSend);
    event.preventDefault();
  };

  /**
   *
   * @param {*} props
   */
  const setupDataModel = (props) => {
    const isButtonPressed = props;
    const isNoErrors = apiTrigger.isNoErrors;
    const isWaitForAction = apiTrigger.isWaitForAction;

    const dataModel = { isButtonPressed, isNoErrors, isWaitForAction };

    setApiTrigger(dataModel);
  };

  return (
    <>
      <NavigationBar />
      <MaterialsApi />
      <TsecReinfErrHandler />
      <TsecReinfApi />
      <Container fluid>
        <Row>
          <Col xxl={1}></Col>
          <Col>
            <h3>{pageTitles.TEE_CALC_TITLE}</h3>
            <Row>
              <Col xxl={5} xl={5} lg={4}>
                <Form>
                  <FormGroupNameInput setName={setName}></FormGroupNameInput>
                  <Row>
                    <FormBvalueInput b={b} setB={setB}></FormBvalueInput>
                  </Row>
                  <Row>
                    <FormHvalueInput h={h} setH={setH}></FormHvalueInput>
                  </Row>
                  {/* ------------------------ h_sl >--- */}
                  <Row>
                    <h6>
                      h<sub>sl</sub> :
                    </h6>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        onChange={(e) => {
                          setH_sl(parseFloat(e.target.value.replace(",", ".")));
                        }}
                      >
                        <Form.Control
                          id="inputH_sl"
                          type="number"
                          placeholder="0.2"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Range
                        id="h_slRange"
                        min="0.1"
                        max="20"
                        step="0.01"
                        value={h_sl}
                        onChange={(changeEvent) => {
                          if (changeEvent.target.value < h) {
                            setH_sl(
                              parseFloat(
                                changeEvent.target.value.replace(",", ".")
                              )
                            );
                            document.getElementById("inputH_sl").value =
                              parseFloat(
                                changeEvent.target.value.replace(",", ".")
                              );
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  {/* ------------------------ b_eff >--- */}
                  <Row>
                    <h6>
                      b<sub>eff</sub> :
                    </h6>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        onChange={(e) => {
                          setB_eff(
                            parseFloat(e.target.value.replace(",", "."))
                          );
                        }}
                      >
                        <Form.Control
                          id="inputB_eff"
                          type="number"
                          placeholder="1"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Range
                        id="h_slRange"
                        min="0.1"
                        max="20"
                        step="0.01"
                        value={b_eff}
                        onChange={(changeEvent) => {
                          if (changeEvent.target.value > b) {
                            setB_eff(
                              parseFloat(
                                changeEvent.target.value.replace(",", ".")
                              )
                            );
                            document.getElementById("inputB_eff").value =
                              parseFloat(
                                changeEvent.target.value.replace(",", ".")
                              );
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormClConcValueInput
                        setCl_conc={setCl_conc}
                        concrete={concrete}
                      ></FormClConcValueInput>
                    </Col>
                    <Col>
                      <FormClSteelValueInput
                        setCl_steel={setCl_steel}
                        steel={steel}
                      ></FormClSteelValueInput>
                    </Col>
                  </Row>
                  <Row>
                    <FormCvalueInput c={c} setC={setC}></FormCvalueInput>
                  </Row>
                  <Row>
                    <Col>
                      <FormFiSValueInput setFi_s={setFi_s}></FormFiSValueInput>
                    </Col>
                    <Col>
                      <FormMsdValueImput setM_sd={setM_sd}></FormMsdValueImput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormFiValueInput setFi={setFi}></FormFiValueInput>
                    </Col>
                    <Col>
                      <FormFiOppValueInput
                        setFi_opp={setFi_opp}
                      ></FormFiOppValueInput>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col>
                <JSXdrawT />
                <br />
                <Container fluid="md">
                  <MDBBtn
                    color="dark"
                    type="button"
                    className="btn btn-primary"
                    aria-controls="example-collapse-text"
                    aria-expanded={true}
                    onClick={(event) => {
                      sendDataToApi(event);
                    }}
                  >
                    Calculate
                  </MDBBtn>
                </Container>
              </Col>
            </Row>
            <br></br>
            <TsecReinfResultView
              isCollapseOpen={true}
              as1={as1}
              ns1={ns1}
              as2={as2}
              ns2={ns2}
              remark={remark}
              remark2={remark2}
            />
          </Col>
          <Col xxl={1}></Col>
        </Row>
      </Container>
    </>
  );
};

export default TsecReinfCalcView;
