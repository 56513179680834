import React, { useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useGlobalContext } from "./Context";
import { pageTitles } from "./utils/StringsConst";

import { jsClasses, log } from "./utils/Utils";

/** Show log in console if true */
const showLogs = false;
/** Class name for log */
const cls = jsClasses.NavigationBar;

const NavigationBar = () => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const { isLogged, setIsLogged } = useGlobalContext();

  useEffect(() => {
    log(cls, "Cookies", cookies, showLogs)
    if (cookies.member !== undefined) {
      log(cls, "cookies.member.length", cookies.member.length, showLogs)
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = (event) => {
    setIsLogged(false);
    event.preventDefault();
    removeCookie("member");
    removeCookie("token");
    log(cls, "Nastąpiło wylogowanie", "", showLogs)
  };

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">CLOUD STRAIN</Navbar.Brand>
          <Nav variant="pills" className="me-auto" defaultActiveKey="/">
            <NavDropdown
              title="Reinforced concrete computations"
              id="nav-dropdown"
              menuVariant="dark"
            >              
              <NavDropdown.Item
                eventKey="4.2"
                href="/calcs/reinforcedconcretecalc"
              >
                {pageTitles.SINGLE_CALC_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3" href="/calcs/doublerectcalc">
                {pageTitles.DOUBLE_CALC_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.4" href="/calcs/rectfindreinfcalc">
                {pageTitles.SINGLE_DIMENSIONING_CALC_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.5" href="/calcs/tsecreinfcalc">
                {pageTitles.TEE_CALC_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.6" href="/calcs/ComplexCalc">
                {pageTitles.COMPLEX_CALC_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.7" href="/calcs/CreepShrinkCalc">
                {pageTitles.creepShrinkCalcTitle}
              </NavDropdown.Item>
              {isLogged === true && (
                <NavDropdown.Item eventKey="4.7" href="/compdatathree">
                  Token access test
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {isLogged === false && (
              <Navbar.Text>
                {" "}
                <a href="/login">Sign In</a>
              </Navbar.Text>
            )}
            {cookies.member !== "" && (
              <Navbar.Text>
                {" "}
                <a href="/login">{cookies.member}</a>{" "}
              </Navbar.Text>
            )}
            <p>..</p>
            {isLogged !== false && (
              <Button
                size="sm"
                variant="secondary"
                onClick={(event) => {
                  logout(event);
                }}
              >
                Logout
              </Button>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
    </>
  );
};

export default NavigationBar;
