const Utlis = () => {};

/**
 * Round the specified number to the specified number of digits after the decimal point
 * @param {Number} number Number for round
 * @param {Integer} decimalPlaces number of digits after the decimal point
 * @returns Rounded noumber
 */
export function roundNumber(number, decimalPlaces) {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
}

/**
 *
 * @param {jsClasses} from From what class massage come
 * @param {String} msg Message
 * @param {Object} obj Object to show
 * @param {Boolean} visibility visibility of message
 */
export function log(jsClass, msg, obj, visibility) {
  if (visibility) console.log("[" + jsClass + "]", msg + ": ", obj);
}

/**
 * Współczynnik zoomu dla lini tekstów (wartości) rysunku. Konieczny by zachować odpwiednie proporcje rysowania tekstu na rysunku.
 * @param {*} value
 * @returns
 */
export function getZoomFactorForTexts(value) {
  return value * 0.05 + value * 0.0425;
}

/**
 * Współczynnik zoomu dla lini wymiarowych rysunku. Konieczny by zachować odpwiednie proporcje rysowania linii na rysunku.
 * @param {*} value
 * @returns
 */
export function getZoomFactorForLines(value) {
  return value * 0.05;
}

/**
 * Zwraca BoundingBox dla tablicy JSXGraph przy rysowaniu wykresów kompleksowego kalkulatora
 * @param {*} array Tablica danych zawierających współrzędne punktów do wykresów kompleksowego kalkulatora
 * @returns Tablica BoundigBox
 */
export function getBoudingBoxArray(array) {
  const DISTANCE_PERCENT = 0.5;

  let x1 = Math.min(...array[1]);
  let x2 = Math.max(...array[1]);
  let y1 = Math.min(...array[0]);
  let y2 = Math.max(...array[0]);

  // eslint-disable-next-line eqeqeq
  if (x1 == x2) {
    // x1 lewy, x2 prawy
    if (x1 < 0) x2 = 0;
    if (x1 > 0) x1 = 0;
  } else if (x1 > 0 && x2 > 0) {
    x1 = 0;
  } else if (x1 < 0 && x2 < 0) {
    x2 = 0;
  }

  // eslint-disable-next-line eqeqeq
  if (y1 == y2) {
    // y1 dół, y2 góra
    if (y1 < 0) y2 = 0;
    if (y1 > 0) y1 = 0;
  } else if (y1 > 0 && y2 > 0) {
    y1 = 0;
  } else if (y1 < 0 && y2 < 0) {
    y2 = 0;
  }

  // eslint-disable-next-line eqeqeq
  if (y1 == 0 && y2 == 0) {
    y1 = -0.1;
    y2 = 0.1;
  }

  // eslint-disable-next-line eqeqeq
  if (x1 == 0 && x2 == 0) {
    x1 = -0.1;
    x2 = 0.1;
  }

  let x_dist = Math.abs(x2 - x1) * DISTANCE_PERCENT;
  let y_dist = Math.abs(y2 - y1) * DISTANCE_PERCENT;

  x1 -= x_dist;
  x2 += x_dist;
  y1 -= y_dist;
  y2 += y_dist;

  return [x1, y2, x2, y1];
}

/**
 * Enum (should) contains all JS Classes from project, List for console log messages
 */
export const jsClasses = {
  TsecReinfApi: "TsecReinfApi",
  TsecReinfCalcView: "TsecReinfCalcView",
  SingleReinfConcreteApi: "SingleReinfConcreteApi",
  JSXdrawT: "JSXdrawT",
  JSXdrawSingle: "JSXdrawSingle",
  SingleReinfConcreteCalcView: "SingleReinfConcreteCalcView",
  SingleDrawDimensionLines: "SingleDrawDimensionLines",
  SingleDrawDimensionRebars: "SingleDrawDimensionRebars",
  MaterialsApi: "MaterialsApi",
  FormSteelSelectOption: "FormSteelSelectOption",
  FormConcreteSelectOption: "FormConcreteSelectOption",
  NavigationBar: "NavigationBar",
  SingleReinfConcreteErrHandler: "SingleReinfConcreteErrHandler",
  DoubleRectCalcView: "DoubleRectCalcView",
  DoubleRectCalcApi: "DoubleRectCalcApi",
  JSXdrawDouble: "JSXdrawDouble",
  SingleDimReinfCalcView: "SingleDimReinfCalcView",
  SingleDimReinfApi: "SingleDimReinfApi",
  TsecDrawRebars: "TsecDrawRebars",
  ComplexMain: "ComplexMain",
  ComplexApi: "ComplexApi",
  ComplexErrHandler: "ComplexErrHandler",
  FormNoOfMainBarsInput: "FormNoOfMainBarsInput",
  FormNoOfOppBarsInput: "FormNoOfOppBarsInput",
  FormPrestessingTendons: "FormPrestessingTendons",
  StrainsInSteelDraw: "StrainsInSteelDraw",
  StressInSteelDraw: "StressInSteelDraw",
  StrainsInConcrateDraw: "StrainsInConcrateDraw",
  StressInConcrateDraw: "StressInConcrateDraw",
  StrainsInTendorsDraw: "StrainsInTendorsDraw",
  StressInTendorsDraw: "StressInTendorsDraw",
  BoxMain: "BoxMain",
  DoubleTeeMain: "DoubleTeeMain",
  TeeMain: "TeeMain",
  RectangleMain: "RectangleMain",
  FormMultipleBvalueInput: "FormMultipleBvalueInput",
  FormMultipleHvalueInput: "FormMultipleHvalueInput",
  RectangleDimensionLines: "RectangleDimensionLines",
  TeeDimensionLines: "TeeDimensionLines",
  DoubleTeeDimensionLines: "DoubleTeeDimensionLines",
  BoxDimensionLines: "BoxDimensionLines",
};

export default Utlis;
