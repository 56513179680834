/**
 * Klasa tworzy punkty konieczne do narysowania dynamicznego rysunku kompleksowego kalkulatora dla sekcji BOX
 */

const BoxPoints = () => {
  return <></>;
};

export function createSectionBoxPoints(
  boardRef,
  xOffsetRef,
  points,
  b1,
  b2,
  b3,
  h1,
  h2,
  h3,
  h,
  pointVisibile,
  showLabel
) {

  const pointProperties = {
    visible: pointVisibile,
    withLabel: showLabel,
    size: 1,
    fillColor: "black",
    strokeColor: "black",
    strokeWidth: 0,
  };

  /** PUNKT 00 */
  points.current[0] = boardRef.create(
    "point",
    [
      xOffsetRef.current,
      function () {
        return h.current;
      },
    ],
    pointProperties
  );
  /** PUNKT 01 */
  points.current[1] = boardRef.create(
    "point",
    [
      function () {
        return points.current[0].X() + b3.current;
      },
      function () {
        return h.current;
      },
    ],
    pointProperties
  );
  /** PUNKT 02 */
  points.current[2] = boardRef.create(
    "point",
    [
      function () {
        return points.current[1].X();
      },
      function () {
        return points.current[1].Y() - h3.current;
      },
    ],
    pointProperties
  );
  /** PUNKT 03 */
  points.current[3] = boardRef.create(
    "point",
    [
      function () {
        return points.current[2].X() - (b3.current - b1.current) / 2;
      },
      function () {
        return points.current[2].Y();
      },
    ],
    pointProperties
  );
  /** PUNKT 04 */
  points.current[4] = boardRef.create(
    "point",
    [
      function () {
        return points.current[3].X();
      },
      function () {
        return points.current[3].Y() - (h2.current + h1.current);
      },
    ],
    pointProperties
  );
  /** PUNKT 05 */
  points.current[5] = boardRef.create(
    "point",
    [
      function () {
        return points.current[4].X() - b1.current;
      },
      function () {
        return points.current[4].Y();
      },
    ],
    pointProperties
  );
  /** PUNKT 06 */
  points.current[6] = boardRef.create(
    "point",
    [
      function () {
        return points.current[5].X();
      },
      function () {
        return points.current[5].Y() + h1.current + h2.current;
      },
    ],
    pointProperties
  );
  /** PUNKT 07 */
  points.current[7] = boardRef.create(
    "point",
    [
      function () {
        return points.current[6].X() - (b3.current - b1.current) / 2;
      },
      function () {
        return points.current[6].Y();
      },
    ],
    pointProperties
  );
  /** PUNKTY OBRYSU WEWNĘTRZNEGO */
  /** PUNKT 08 */
  points.current[8] = boardRef.create(
    "point",
    [
      function () {
        return points.current[3].X() - b2.current / 2;
      },
      function () {
        return points.current[3].Y();
      },
    ],
    pointProperties
  );
  /** PUNKT 09 */
  points.current[9] = boardRef.create(
    "point",
    [
      function () {
        return points.current[8].X();
      },
      function () {
        return points.current[8].Y() - h2.current;
      },
    ],
    pointProperties
  );
  /** PUNKT 10 */
  points.current[10] = boardRef.create(
    "point",
    [
      function () {
        return points.current[9].X() - (b1.current - b2.current);
      },
      function () {
        return points.current[9].Y();
      },
    ],
    pointProperties
  );
  /** PUNKT 11 */
  points.current[11] = boardRef.create(
    "point",
    [
      function () {
        return points.current[10].X();
      },
      function () {
        return points.current[10].Y() + h2.current;
      },
    ],
    pointProperties
  );
}

export default BoxPoints;
