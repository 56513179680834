/**
 * Klasa wspólna zawierająca funkcje do rysowania prętów zbrojeniowych
 */

const RebarsDrawCommon = () => {};

/**
 * Zwraca ilość rzędów prętów zbrojeniowych w przekroju
 * @param {*} no_of_bars
 * @param {*} fi
 * @param {*} fi_s
 * @param {*} b
 * @param {*} c
 * @param {*} sz
 * @returns Rzędy prętów zbrojeniowych
 */
export function requiredRows(no_of_bars, fi, fi_s, b, c, sz) {
  var remainder =
    no_of_bars.current % maxBarsInRow(no_of_bars, fi, fi_s, b, c, sz);
  var value;
  if (remainder === 0) {
    value = Math.floor(
      no_of_bars.current / maxBarsInRow(no_of_bars, fi, fi_s, b, c, sz)
    );
  } else {
    value =
      Math.floor(
        no_of_bars.current / maxBarsInRow(no_of_bars, fi, fi_s, b, c, sz)
      ) + 1;
  }
  return value;
}

/**
 * Zwraca maksymalną ilość prętów zbrojonych dla 1 rzędu przekroju
 * @param {*} no_of_bars TODO: usunąć
 * @param {*} fi
 * @param {*} fi_s
 * @param {*} b
 * @param {*} c
 * @param {*} sz szczelina
 * @returns Maksymalna ilość prętów
 */
export function maxBarsInRow(no_of_bars, fi, fi_s, b, c, sz) {
  var width =
    b.current -
    (fi_s.current / 1000) * 2 -
    (c.current / 1000) * 2 -
    fi.current / 1000;
  var value = Math.floor(width / ((fi.current + sz) / 1000)) + 1;
  return value;
}

/**
 * Odległość pomiędzy osiami skrajnych (bocznych) prętów w danym rzędzie
 */
export function widthBtwBars(fi, fi_s, b, c) {
  var value =
    b.current -
    (fi_s.current / 1000) * 2 -
    (c.current / 1000) * 2 -
    fi.current / 1000;
  return value;
}

/* Skok pomiędzy prętami zbrojonymi */
export function getJumpX(no_of_bars, fi, fi_s, b, c) {
  return widthBtwBars(fi, fi_s, b, c) / (no_of_bars - 1);
}

/**
 * Zwraca tablicę ilości prętów zbrojeniowych dla każdego rzędu w przekroju
 * @param {*} no_of_bars Ilość wszystkich prętów zbrojeniowych
 * @param {*} reqRows Rzędy prętów zbrojeniowych
 * @returns Tablica ilości prętów dla rzędów zbrojenia
 */
export function getArrayOfBarsInRow(no_of_bars, reqRows, maxBarsInRow) {
  const arrOfBars = [];

  for (let i = 0; i < reqRows; i++) {
    arrOfBars.push(maxBarsInRow);
  }

  let sumOfBars = 0;

  for (let i = 0; i < arrOfBars.length; i++) {
    sumOfBars += arrOfBars[i];
  }

  let restOfBar = sumOfBars - no_of_bars.current;
  arrOfBars[arrOfBars.length - 1] = arrOfBars[arrOfBars.length - 1] - restOfBar;

  return arrOfBars;
}

/**
 * Zwraca tablicę współrzędnych X prętów zbrojeniowych
 * @returns Tablica współrzędnych X
 */
export function getBarsXcoords(startX, maxBarsInOneRow, fi, fi_s, b, c) {
  /* Tablica współrzędnych x prętów zbrojeniowych jeżeli znajdują się w rzędzie większym niż 1 */
  const xCoordsArrTemp = [];
  const xFinalCoordsArr = [];

  for (let i = 0; i < maxBarsInOneRow; i++) {
    xCoordsArrTemp.push(startX + i * getJumpX(maxBarsInOneRow, fi, fi_s, b, c));
  }

  for (let i = 0; i < xCoordsArrTemp.length - 1; i++) {
    xFinalCoordsArr.push(xCoordsArrTemp[i]);
    if (i === xCoordsArrTemp.length - 1 - i) break;
    xFinalCoordsArr.push(xCoordsArrTemp[xCoordsArrTemp.length - 1 - i]);
  }

  return xFinalCoordsArr;
}

export default RebarsDrawCommon;
