import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import JSXBoard from "../../JSXBoard";

import { useGfxContext } from "../../context/GfxContext";

import { log, jsClasses } from "../../utils/Utils";

import { drawDimensionTexts } from "./DoubleDrawTexts";
import { drawDimensionLines } from "./DoubleDrawDimensionLines";
import { drawDimensionRebars } from "./DoubleDrawDimensionRebars";
import { drawXeff } from "./DoubleDrawXeff";

/** Show log in console if true */
const showLogs = true;
/** Class name for log */
const cls = jsClasses.JSXdrawDouble;

let pointVisibile = true;
let showLabel = false;

const JSXdrawDouble = () => {
  const xOffsetRef = useRef(0);
  const boardRef = useRef();
  const pAref = useRef();
  const pBref = useRef();
  const pCref = useRef();
  const pDref = useRef();

  const b = useRef();
  const h = useRef();
  const c = useRef();
  const fi = useRef();
  const fi_opp = useRef();
  const fi_s = useRef();
  const no_of_bars = useRef();
  const no_of_opp_bars = useRef();
  const x_eff = useRef();
  const x_effVisibility = useRef();

  const { doubleDrawData } = useGfxContext();

  useEffect(() => {
    log(cls, "Board", boardRef.current, showLogs);
    if (boardRef.current !== undefined) {
      createPoints();
      createDraw();
      updateBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log(cls, "Data for draw", doubleDrawData, showLogs);
    if (
      !doubleDrawData.b.isNaN &&
      !doubleDrawData.h.isNaN &&
      !doubleDrawData.c.isNaN
    ) {
      x_eff.current = doubleDrawData.x_eff;

      if (b.current !== doubleDrawData.b) {
        x_eff.current = 0;
      }

      x_eff.current !== 0 && x_eff.current !== undefined
        ? (x_effVisibility.current = true)
        : (x_effVisibility.current = false);

      b.current = doubleDrawData.b;
      h.current = doubleDrawData.h;
      c.current = doubleDrawData.c;
      fi.current = doubleDrawData.fi;
      fi_opp.current = doubleDrawData.fi_opp;
      fi_s.current = doubleDrawData.fi_s;
      no_of_bars.current = doubleDrawData.no_of_bars;
      no_of_opp_bars.current = doubleDrawData.no_of_opp_bars;
      if (b.current > 0.1) {
        drawDimensionRebars(
          boardRef.current,
          no_of_bars,
          no_of_opp_bars,
          fi,
          fi_opp,
          fi_s,
          b,
          c,
          pAref.current,
          pBref.current,
          pCref.current,
          pDref.current
        );
        updateBoard();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doubleDrawData]);

  const updateBoard = () => {
    if (boardRef.current !== undefined) {
      log(cls, "refB", b.current, showLogs);
      log(cls, "refH", h.current, showLogs);
      boardRef.current.fullUpdate();
      boardRef.current.zoomElements([
        pAref.current,
        pBref.current,
        pCref.current,
        pDref.current,
      ]);
      log(cls, "pBx", pBref.current.X(), showLogs);
      log(cls, "pBy", pBref.current.Y(), showLogs);
    }
  };

  /**
   * Create points for draw
   */
  const createPoints = () => {
    /* point A */
    pAref.current = boardRef.current.create(
      "point",
      [
        xOffsetRef.current,
        function () {
          return h.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );
    log(cls, "pA", pAref.current, showLogs);

    /* point B */
    pBref.current = boardRef.current.create(
      "point",
      [
        function () {
          return pAref.current.X() + b.current;
        },
        function () {
          return h.current;
        },
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );
    log(cls, "pB: ", pBref.current, showLogs);

    /* point C */
    pCref.current = boardRef.current.create(
      "point",
      [
        function () {
          return b.current;
        },
        0,
      ],
      {
        visible: pointVisibile,
        withLabel: showLabel,
        size: 1,
        fillColor: "black",
        strokeColor: "black",
        strokeWidth: 0,
      }
    );
    log(cls, "pC: ", pCref.current, showLogs);

    /* point D */
    pDref.current = boardRef.current.create("point", [xOffsetRef.current, 0], {
      visible: pointVisibile,
      withLabel: showLabel,
      size: 1,
      fillColor: "black",
      strokeColor: "black",
      strokeWidth: 0,
    });
    log(cls, "pD: ", pDref.current, showLogs);
  };

  /**
   * Create draw
   */
  const createDraw = () => {
    boardRef.current.create(
      "polygon",
      [pAref.current, pBref.current, pCref.current, pDref.current],
      {
        borders: { strokeWidth: 5, strokeColor: "black" },
        fillOpacity: 1,
        fillColor: '#cdfee0'
      }
    );

    drawDimensionTexts(
      boardRef.current,
      h,
      b,
      c,
      pAref.current,
      pBref.current,
      pCref.current
    );

    drawDimensionLines(
      boardRef.current,
      c,
      pAref.current,
      pBref.current,
      pCref.current,
      pDref.current
    );

    drawXeff(
      boardRef.current,
      x_eff,
      pAref.current,
      pBref.current,
      x_effVisibility
    );

    drawDimensionRebars(
      boardRef.current,
      no_of_bars,
      no_of_opp_bars,
      fi,
      fi_opp,
      fi_s,
      b,
      c,
      pAref.current,
      pBref.current,
      pCref.current,
      pDref.current
    );
  };

  const gfx = (board) => {
    boardRef.current = board;
  };

  return (
    <Container>
      <JSXBoard
        logic={gfx}
        boardAttributes={{
          axis: false,
          boundingbox: [-0.1, 2, 2, -0.25],
          showCopyright: false,
        }}
        style={{
          border: "1px solid black",
        }}
      />
    </Container>
  );
};

export default JSXdrawDouble;
