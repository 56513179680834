import { log, jsClasses } from "../../utils/Utils";
import {
  requiredRows,
  maxBarsInRow,
  widthBtwBars,
  getJumpX,
  getArrayOfBarsInRow,
  getBarsXcoords,
} from "../RebarsDrawCommon";

var bars = [];

const DoubleDrawDimensionRebars = () => {};

/** Show log in console if true */
const showLogs = true;
/** Class name for log */
const cls = jsClasses.SingleDrawDimensionRebars;

export function drawDimensionRebars(
  board,
  no_of_bars,
  no_of_opp_bars,
  fi,
  fi_opp,
  fi_s,
  b,
  c,
  pA,
  pB,
  pC,
  pD
) {
  board.suspendUpdate();
  board.removeObject(bars);
  board.unsuspendUpdate();
  bars = [];
  drawRebars(board, no_of_bars, fi, fi_s, b, c, pD);
  drawOppRebars(board, no_of_opp_bars, fi_opp, fi_s, b, c, pA);
}

/**
 * Rysuje zbrojenie na górze rysunku
 */
function drawOppRebars(board, no_of_opp_bars, fi_opp, fi_s, b, c, refPoint) {
  if (no_of_opp_bars.current === 1) drawOneOppBar(board, b, c, refPoint, fi_opp);
  else drawManyOppBars(board, no_of_opp_bars, fi_opp, fi_s, b, c, refPoint);
}

/**
 * Rysuje górny pręt zbrojeniowy jeżeli występuje tylko jeden.
 */
function drawOneOppBar(board, b, c, refPoint, fi_opp) {
  var el = board.create(
    "point",
    [
      function () {
        return refPoint.X() + b.current / 2;
      },
      function () {
        return -0.01 + refPoint.Y() - c.current / 1000;
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: fi_opp.current / 10,
    }
  );
  bars.push(el);
}

/**
 * Metoda rysująca górne pręty kiedy ich ilość jest większa > 1
 */
function drawManyOppBars(board, no_of_opp_bars, fi, fi_s, b, c, refPoint) {
  let reqRows = requiredRows(no_of_opp_bars, fi, fi_s, b, c, 32);
  let wdtBtwBrs = widthBtwBars(fi, fi_s, b, c);
  let maxBarsInOneRow = maxBarsInRow(no_of_opp_bars, fi, fi_s, b, c, 32);
  let arrayOfBars = getArrayOfBarsInRow(
    no_of_opp_bars,
    reqRows,
    maxBarsInOneRow
  );

  log(
    cls,
    "Redraw many rebars",
    { no_of_opp_bars, fi, fi_s, b, c, refPoint },
    showLogs
  );

  /* Początkowy X */
  let startX = refPoint.X() + (b.current - wdtBtwBrs) / 2;
  /* Początkowy Y */
  let startY = refPoint.Y() - fi.current / 1000 - c.current / 1000;

  const xCoordsArr = getBarsXcoords(startX, maxBarsInOneRow, fi, fi_s, b, c);

  log(cls, "No of bars", no_of_opp_bars.current, showLogs);
  log(cls, "Required rows", reqRows, showLogs);
  log(cls, "Max bars in one row", maxBarsInOneRow, showLogs);
  log(cls, "Array of bars", arrayOfBars, showLogs);
  log(cls, "Width between bars", wdtBtwBrs, showLogs);
  log(cls, "Start X, Y", { startX, startY }, showLogs);

  for (let i = 0; i < arrayOfBars.length; i++) {
    for (let j = 0; j < arrayOfBars[i]; j++) {
      if (i < 1) {
        var el = board.create(
          "point",
          [
            function () {
              return startX + j * getJumpX(arrayOfBars[i], fi, fi_s, b, c);
            },
            function () {
              return startY - i * getJumpX(maxBarsInOneRow, fi, fi_s, b, c);
            },
          ],
          {
            visible: true,
            withLabel: false,
            size: fi.current / 10,
          }
        );
        bars.push(el);
      } else {
        el = board.create(
          "point",
          [
            function () {
              return xCoordsArr[j];
            },
            function () {
              return startY - i * getJumpX(maxBarsInOneRow, fi, fi_s, b, c);
            },
          ],
          {
            visible: true,
            withLabel: false,
            size: fi.current / 10,
          }
        );
        bars.push(el);
      }
    }
  }

  log(cls, "Bars array", bars, showLogs);
}

/**
 * Rysuje zbrojenie na dole rysunku
 */
function drawRebars(board, no_of_bars, fi, fi_s, b, c, pD) {
  if (no_of_bars.current === 1) drawOneBar(board, b, c, pD, fi);
  else drawManyBars(board, no_of_bars, fi, fi_s, b, c, pD);
}

/**
 * Draw one bar
 */
function drawOneBar(board, b, c, pD, fi) {
  var el = board.create(
    "point",
    [
      function () {
        return pD.X() + b.current / 2;
      },
      function () {
        return 0.01 + pD.Y() + c.current / 1000;
      },
    ],
    {
      visible: true,
      withLabel: false,
      size: fi.current / 10,
    }
  );
  bars.push(el);
}

/**
 * Metoda rysująca pręty kiedy ich ilość jest większa > 1
 * @param {*} board Tablica
 * @param {*} no_of_bars Referencja do ilości prętów
 * @param {*} fi
 * @param {*} fi_s
 * @param {*} b
 * @param {*} c
 * @param {*} pD
 */
function drawManyBars(board, no_of_bars, fi, fi_s, b, c, pD) {
  let reqRows = requiredRows(no_of_bars, fi, fi_s, b, c, 32);
  let wdtBtwBrs = widthBtwBars(fi, fi_s, b, c);
  let maxBarsInOneRow = maxBarsInRow(no_of_bars, fi, fi_s, b, c, 32);
  let arrayOfBars = getArrayOfBarsInRow(no_of_bars, reqRows, maxBarsInOneRow);

  log(cls, "Redraw many rebars", { no_of_bars, fi, fi_s, b, c, pD }, showLogs);

  /* Początkowy X */
  let startX = pD.X() + (b.current - wdtBtwBrs) / 2;
  /* Początkowy Y */  
  let startY = fi.current / 1000 + pD.Y() + c.current / 1000;

  /* Tablica współrzędnych x prętów zbrojeniowych jeżeli znajdują się w rzędzie większym niż 1 */
  const xCoordsArr = getBarsXcoords(startX, maxBarsInOneRow, fi, fi_s, b, c);

  log(cls, "No of bars", no_of_bars.current, showLogs);
  log(cls, "Required rows", reqRows, showLogs);
  log(cls, "Max bars in one row", maxBarsInOneRow, showLogs);
  log(cls, "Array of bars", arrayOfBars, showLogs);
  log(cls, "Width between bars", wdtBtwBrs, showLogs);
  log(cls, "Start X, Y", { startX, startY }, showLogs);

  for (let i = 0; i < arrayOfBars.length; i++) {
    for (let j = 0; j < arrayOfBars[i]; j++) {
      if (i < 1) {
        var el = board.create(
          "point",
          [
            function () {
              return startX + j * getJumpX(arrayOfBars[i], fi, fi_s, b, c);
            },
            function () {
              return startY + i * getJumpX(maxBarsInOneRow, fi, fi_s, b, c);
            },
          ],
          {
            visible: true,
            withLabel: false,
            size: fi.current / 10,
          }
        );
        bars.push(el);
      } else {
        el = board.create(
          "point",
          [
            function () {
              return xCoordsArr[j];
            },
            function () {
              return startY + i * getJumpX(maxBarsInOneRow, fi, fi_s, b, c);
            },
          ],
          {
            visible: true,
            withLabel: false,
            size: fi.current / 10,
          }
        );
        bars.push(el);
      }
    }
  }

  log(cls, "Bars array", bars, showLogs);
}

export default DoubleDrawDimensionRebars;
