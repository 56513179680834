/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import { Row, Form, Col, Table } from "react-bootstrap";

import { jsClasses, log, roundNumber } from "../../utils/Utils";

import { useGfxContext } from "../../context/GfxContext";

/** Show log in console if true, class name for log */
const showLogs = true;
const cls = jsClasses.FormPrestessingTendons;

const FormPrestessingTendons = (props) => {
  const [isTendors, setTendors] = useState("1");
  const [noOfRows, setNoOfRows] = useState("1");
  const noOfRowsRef = useRef(1);
  const tendonsArr = useRef([]);
  const arr = useRef([
    [0.2, 4, 19, 150],
    [0.4, 4, 19, 150],
    [0.6, 4, 19, 150],
    [0.8, 4, 19, 150],
    [1.0, 4, 19, 150],
    [1.2, 4, 19, 150],
    [1.4, 4, 19, 150],
    [1.6, 4, 19, 150],
    [1.8, 4, 19, 150],
    [2.0, 4, 19, 150],
    [2.2, 4, 19, 150],
    [2.4, 4, 19, 150],
  ]);

  const { setComplexDrawData, complexDrawData } = useGfxContext();

  const updateArray = (rowIndex, colIndex, val) => {
    let subArray = arr.current[rowIndex];
    subArray[colIndex] = val;

    tendonsArr.current = [];
    tendonsArr.current.push(parseFloat(noOfRowsRef.current));
    for (let i = 0; i < noOfRowsRef.current; i++)
      tendonsArr.current.push(arr.current[i]);

    props.setTendon_info.current = tendonsArr.current;
    redrawTendons();
  };

  const updateTendorsNumber = () => {
    tendonsArr.current = [];
    tendonsArr.current.push(parseFloat(noOfRowsRef.current));
    for (let i = 0; i < noOfRowsRef.current; i++)
      tendonsArr.current.push(arr.current[i]);
    props.setTendon_info.current = tendonsArr.current;
    redrawTendons();
  };

  /**
   * Aktualizuje kontekstową zmienną przechowującą informacje z danymi do przerysowania cięgien, wymuszając tym samym ich przerysowanie
   */
  const redrawTendons = () => {
    setComplexDrawData({
      b: complexDrawData.b,
      h: complexDrawData.h,
      tendon_info: tendonsArr.current,
    });
  };

  return (
    <>
      <Row>
        <FormIsCrossSection setTendors={setTendors}></FormIsCrossSection>
      </Row>
      {isTendors === "1" && (
        <>
          <Row>
            <Col>
              <FormNoOfRowsTendons
                setNoOfRows={setNoOfRows}
                noOfRowsRef={noOfRowsRef}
                updateTendorsNumber={updateTendorsNumber}
              ></FormNoOfRowsTendons>
            </Col>
            <Col>
              <FormCompressionEff
                prestr_ef_in_mn={props.prestr_ef_in_mn}
                setPrestr_ef_in_mn={props.setPrestr_ef_in_mn}
              ></FormCompressionEff>
            </Col>
          </Row>
          <Row>
            <FormTendomValues
              noOfRows={noOfRows}
              updateArray={updateArray}
            ></FormTendomValues>
          </Row>
        </>
      )}
    </>
  );
};

function FormTendomValues(props) {
  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>No of row</th>
            <th>
              Vertical position of the tendon row axis (from the bottom) [m]:
            </th>
            <th>Number of tendons in row: </th>
            <th>Number of strands in each tendon of row:</th>
            <th>The cross-sectional area of single strand [mm2] in row:</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: props.noOfRows }).map((_, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <Form.Group
                  className="mb-3"
                  onChange={(e) => {
                    props.updateArray(
                      index,
                      0,
                      parseFloat(e.target.value.replace(",", "."))
                    );
                  }}
                >
                  <Form.Control
                    id={"inp" + index + "_0"}
                    type="number"
                    placeholder={roundNumber(index * 0.2 + 0.2, 1)}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group
                  className="mb-3"
                  onChange={(e) => {
                    props.updateArray(
                      index,
                      1,
                      parseFloat(e.target.value.replace(",", "."))
                    );
                  }}
                >
                  <Form.Control
                    id={"inp" + index + "_1"}
                    type="number"
                    placeholder="4"
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group
                  className="mb-3"
                  onChange={(e) => {
                    props.updateArray(
                      index,
                      2,
                      parseFloat(e.target.value.replace(",", "."))
                    );
                  }}
                >
                  <Form.Control
                    id={"inp" + index + "_2"}
                    type="number"
                    placeholder="19"
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group
                  className="mb-3"
                  onChange={(e) => {
                    props.updateArray(
                      index,
                      3,
                      parseFloat(e.target.value.replace(",", "."))
                    );
                  }}
                >
                  <Form.Control
                    id={"inp" + index + "_3"}
                    type="number"
                    placeholder="150"
                  />
                </Form.Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function FormNoOfRowsTendons(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="noOfRows"
      onChange={(e) => {
        props.setNoOfRows(parseFloat(e.target.value.replace(",", ".")));
        props.noOfRowsRef.current = parseFloat(
          e.target.value.replace(",", ".")
        );
        props.updateTendorsNumber();
        log(cls, "no of rows", props.noOfRowsRef.current, showLogs);
      }}
    >
      <Form.Label>Number of rows of tendons: </Form.Label>
      <Form.Select aria-label="Default select example" placeholder="1">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
      </Form.Select>
    </Form.Group>
  );
}

function FormIsCrossSection(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="fi"
      onChange={(e) => {
        props.setTendors(e.target.value);
      }}
    >
      <Form.Label>Prestessing tendons in cross section?</Form.Label>
      <Form.Select aria-label="Default select example" placeholder="32">
        <option value="1" selected>
          Yes
        </option>
        <option value="0">No</option>
      </Form.Select>
    </Form.Group>
  );
}

function FormCompressionEff(props) {
  return (
    <Form.Group
      className="mb-3"
      controlId="fi"
      onChange={(e) => {
        if (e.target.value === "true") props.setPrestr_ef_in_mn(true);
        else props.setPrestr_ef_in_mn(false);
      }}
    >
      <Form.Label>Compression effect contained in Msd and Nsd?</Form.Label>
      <Form.Select
        aria-label="Default select example"
        placeholder={props.prestr_ef_in_mn}
      >
        <option value={true}>Yes</option>
        <option value={false} selected>
          No
        </option>
      </Form.Select>
    </Form.Group>
  );
}

export default FormPrestessingTendons;
