import { roundNumber, getZoomFactorForTexts } from "../../../utils/Utils";

const RectangleValues = () => {};

const FONT_SIZE = 14;

export function drawValues(board, h, b, points) {
  draw_H_Text(board, points, h);
  draw_B_Text(board, points, b);
}

/**
 * Rysuje wymiar h na tablicy
 */
function draw_H_Text(board, points, h) {
  board.create(
    "text",
    [
      function () {
        return (
          points.current[0].X() -
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return points.current[0].Y() / 2;
      },
      function () {
        return "h = " + roundNumber(h.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE, rotate: 90, display: "internal" }
  );
}

/**
 * Rysuje wymiar b na tablicy
 */
function draw_B_Text(board, points, b) {
  board.create(
    "text",
    [
      function () {
        return (points.current[1].X() - points.current[0].X()) / 2 - 0.08;
      },
      function () {
        return (
          points.current[0].Y() +
          getZoomFactorForTexts(points.current[0].Y() + points.current[1].X())
        );
      },
      function () {
        return "b = " + roundNumber(b.current, 2) + " m";
      },
    ],
    { fontSize: FONT_SIZE }
  );
}

export default RectangleValues;
