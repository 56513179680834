import React from "react";
import { Table, Row, Col } from "react-bootstrap";

import StrainsInSteelDraw from "../../graphics/dynamicDrawComplex/resultDraws/StrainsInSteelDraw";
import StressInSteelDraw from "../../graphics/dynamicDrawComplex/resultDraws/StressInSteelDraw"
import StrainsInConcrateDraw from "../../graphics/dynamicDrawComplex/resultDraws/StrainsInConcrateDraw";
import StressInConcrateDraw from "../../graphics/dynamicDrawComplex/resultDraws/StressInConcrateDraw"
import StrainsInTendorsDraw from "../../graphics/dynamicDrawComplex/resultDraws/StrainsInTendorsDraw"
import StressInTendorsDraw from "../../graphics/dynamicDrawComplex/resultDraws/StressInTendorsDraw"


const ComplexResultView = ({ json }) => {
  return (
    <>
      <Table>
        <Row>
          <Col>
            <h4>Strains in steel</h4>
            <StrainsInSteelDraw />
          </Col>
          <Col>
            <h4>Stress in steel</h4>
            <StressInSteelDraw />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Strains in concrete</h4>
            <StrainsInConcrateDraw />
          </Col>
          <Col>
            <h4>Stress in concrete</h4>
            <StressInConcrateDraw />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Strains in tendors</h4>
            <StrainsInTendorsDraw />
          </Col>
          <Col>
            <h4>Stress in tendors</h4>
            <StressInTendorsDraw />
          </Col>
        </Row>
      </Table>
    </>
  );
};

export default ComplexResultView;
